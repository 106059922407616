import { GET_8323s } from '../actions/actionTypes';

const initialState = {
  list: []
}


export const reportReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_8323s:
      return {
        ...state,
        list: payload
      };

    default:
      return state;
  }
}