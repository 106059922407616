// react
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// redux
import { loginUser, checkAuth } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { compose } from "redux";

// material ui
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// components
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import Spinner from "../_common/Spinner";

// styles
const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  logoContainer: {
    display: "flex",
    padding: "16px",
  },
  logo: {
    margin: "0 auto",
  },
  card: {
    width: "500px",
    //marginTop: "20%"
  },
  cardInput: {
    marginTop: "16px",
  },
};

function LoginPage(props) {
  const { classes } = props;
  const auth_token = localStorage.getItem("auth_token");

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] =
    useState(false);

  useEffect(() => {
    props.checkAuth(auth_token);
  });

  const onEmailAddressChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.loginUser(emailAddress, password);
  };

  // dialog functions
  const onForgotPassword = () => {
    setShowForgotPasswordDialog(true);
  };

  const handleDialogClose = () => {
    setShowForgotPasswordDialog(false);
  };

  return (
    <div className={classes.root}>
      {props.isLoading ? <Spinner /> : null}

      {props.isAuthenticated ? (
        <Redirect
          to={
            props.location.state ? props.location.state.from : "/organizations"
          }
        />
      ) : null}

      {showForgotPasswordDialog ? (
        <ForgotPasswordDialog
          open={showForgotPasswordDialog}
          handleClose={handleDialogClose}
        />
      ) : null}

      <Card className={classes.card}>
        <div className={classes.logoContainer}>
          <div className={classes.logo}>
            <img
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="CanDrive"
              width="200"
            />
            <Typography align="center">Admin Login</Typography>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <CardContent>
            <Grid container>
              {/* username */}
              <Grid item xs={12}>
                <Input
                  type="email"
                  placeholder="Email *"
                  value={emailAddress}
                  onChange={onEmailAddressChange}
                  fullWidth={true}
                  className={classes.cardInput}
                  autoFocus={true}
                  required
                />
              </Grid>

              {/* password */}
              <Grid item xs={12}>
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password *"
                  name="password"
                  value={password}
                  onChange={onPasswordChange}
                  fullWidth={true}
                  className={classes.cardInput}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? (
                          <Tooltip title="Hide password">
                            <Visibility />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Show password">
                            <VisibilityOff />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container>
              <Button color="primary" onClick={onForgotPassword}>
                Forgot Password?
              </Button>
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                type="submit"
              >
                Login
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
  };
};

export default compose(
  connect(mapStateToProps, { loginUser, checkAuth }),
  withStyles(styles)
)(LoginPage);
