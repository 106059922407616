import { LOGIN_USER, LOGGED_IN_USER, LOGOUT_USER, LOGIN_FAIL } from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  isAdmin: false
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoading: true,
      };

    case LOGGED_IN_USER:
      if ((payload.roles != undefined) && Boolean(payload.roles.find(role => role === "admin"))) { //user is logging in WITHOUT a token
        return {
          isAuthenticated: true,
          isLoading: false,
          isAdmin: true,
          ...payload
        }
      }
      else if (Boolean(payload.role.find(role => role === "admin"))) { //user is logging in WITH a token
        return {
          isAuthenticated: true,
          isLoading: false,
          isAdmin: true,
          ...payload
        }
      }
      else
        return initialState;

    case LOGIN_FAIL:
    case LOGOUT_USER:
      localStorage.clear();
      return initialState;

    default:
      return state;
  }
}
