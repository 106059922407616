// react
import React, { useState, useEffect } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getOrganizations } from '../../redux/actions/organizationActions';
import { getCanDrives } from '../../redux/actions/canDrivesActions';
import { get8323Reports } from '../../redux/actions/reportActions';

// material ui
import {
  Avatar, FormControl, Button, Input,
  InputAdornment, MenuItem,
  Paper, Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow, TableSortLabel,
  Typography, withStyles, InputLabel, Select, Grid, TextField
} from '@material-ui/core';
import { Search, AttachMoney } from '@material-ui/icons';

// components
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import { exportToCsvBlob } from '../_common/spudExportCSV/array';
import { blobToFile } from '../_common/spudExportCSV/blob';

//icons

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10%',
    width: '100%'
  },

  paperContainer: {
    // overflowX: 'auto'
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  selectFieldsContainer: {
    flexShrink: 1,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },

  avatar: {
    background: '#2780e3',
    marginRight: '8px'
  },
  title: {
    '& span': {
      color: 'gray'
    }
  },

  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    margin: '0 16px'
  },
  search: {
    width: '60%'
  },

  menu: {
    overflowX: 'none',
    overflowY: 'none'
  },

  highlightedManualReconAmount: {
    color: 'red',
    fontWeight: '900',
  },

  aboveTable: {
    flexShrink: 0,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
  },

  tableHead: {
    // border: 'solid 1px green',

  },

  table: {
    // overflowX: 'auto'
  },

  tableBody: {

  },

  tableRow: {
    '&:hover': {
      background: 'rgb(245, 245, 245)',
    }
  },

  tableCell: {
    // border: 'dashed 1px blue',
    paddingRight: '5px',
    paddingLeft: '5px',
    textOverflow: 'ellipsis'
    // whiteSpace: 'nowrap',
    // wordBreak: 'break-all'
  }
};

function EightThreeTwoThreeReports(props) {
  const { classes } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');

  // rowsPerPage and setRowsPerPage are used for the TablePagination component.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  //  These state values are used for search functionality
  const [searchValue, setSearchValue] = useState(null);
  const [filteredArray, setFilteredArray] = useState([]);
  // const [noneFound, setNoneFound] = useState(false);

  const [calendarYear, setCalendarYear] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState('');
  const [canDrives, setCanDrives] = React.useState([]);
  const [reports, setReports] = React.useState([]);

  useEffect(() => {
    props.get8323Reports();
    props.getOrganizations();
  }, [null]);

  useEffect(() => {
    if (props.organizations !== undefined) {
      setOrganizations(props.organizations);
    }
  }, [props.organizations]);

  useEffect(() => {
    if (props.reports !== undefined) {

      let groupedPickups = [];
      let adHocPickups = props.reports.filter(report => report.canDriveTypeId === 3);
      let canDrives = props.reports.filter(report => report.canDriveTypeId !== 3);

      adHocPickups.forEach(pickup => {
        const { date } = pickup;
        if (pickup.isReconciled) {
          pickup.title = "Ad Hoc Pickups " + moment(date).format("MM/DD/YYYY");
        }

        if (groupedPickups.some(item => moment(item.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY") && item.organization && item.organization?.name === pickup.organization?.name)) {
          groupedPickups.map(groupedPickup => {
            if (moment(groupedPickup.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY")) {
              groupedPickup.totalApproximateCansCollected += pickup.totalApproximateCansCollected;
              groupedPickup.estimatedBottleDeposit = parseFloat(groupedPickup.estimatedBottleDeposit) + parseFloat(pickup.estimatedBottleDeposit);
            }
          })
        } else {
          groupedPickups.push(pickup);
        }
      })

      setReports([...groupedPickups, ...canDrives]);
    }
  }, [props.reports]);

  useEffect(() => {
    search(searchValue, selectedOrganization, calendarYear);
    console.log(searchValue, selectedOrganization, calendarYear)
  }, [searchValue, calendarYear, selectedOrganization]);

  const roundN = (num, n) => {
    return parseFloat(Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);
  }

  function search(searchValue, selectedOrganization, calendarYear) {
    var filteredArray = reports.filter((item, index) => {
      if (selectedOrganization) {
        let result = true;
        console.log("In search...");
        console.log("calendarYear: ", calendarYear);
        console.log("item: ", item);

        if (calendarYear) {
          let searchYear = calendarYear.format("YYYY");
          let itemDate = moment(item.date).format("YYYY");
          result = itemDate.includes(searchYear)
          if (!result) return result;
        }

        if (searchValue) {
          result = item.title.toLowerCase().includes(searchValue.toLowerCase())
          if (!result) return result;
        }

        if (selectedOrganization) {
          result = item.organization && item.organization?.name.toLowerCase().includes(selectedOrganization.toLowerCase());
          if (!result) return result;
        }

        return result;
      } else {
        return false;
      }
    });
    setFilteredArray(filteredArray);
  }

  // table sorting functionality
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const onSort = property => event => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  // pagination functions
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function onSearchChange(e) {
    setSearchValue(e.target.value);
  }

  const onSelectOrganization = e => {
    setSelectedOrganization(e.target.value);
  }

  const onCalendarYearChange = value => {
    console.log("value: ", value);
    setCalendarYear(value);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <Paper className={classes.paperContainer}>
          <div className={classes.aboveTable}>
            <div className={classes.titleContainer}>
              <Avatar className={classes.avatar}>
                {/* <Person /> */}
                <AttachMoney />
              </Avatar>
              <Typography variant="h6" className={classes.title}>
                8323 Reports
            </Typography>
            </div>
            {/* Search Bar */}
            <div className={classes.searchContainer}>
              <FormControl fullWidth className={classes.search}>
                <InputLabel htmlFor="standard-adornment-amount">Search</InputLabel>
                <Input
                  name="searchValue"
                  id="standard-adornment-amount"
                  value={searchValue}
                  // placeholder="Search"
                  onChange={onSearchChange}
                  startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
                />
              </FormControl>
            </div>
            <div className={classes.actionsContainer}>
              {/* Export CSV Button */}
              <Button variant="outlined" color="primary"
                onClick={() => {
                  let formattedAndFiltered = [];
                  filteredArray.forEach(item => {
                    let data = item;
                    let organizationName = item.organization?.name;
                    data =
                    {
                      ...data,
                      organizationName
                    }
                    formattedAndFiltered.push(data);
                  });
                  let exportedCSV = exportToCsvBlob(formattedAndFiltered,
                    [
                      'organizationName', 'title', 'date',
                      'canDriveTypeId', 'driveReconType', 'totalApproximateCansCollected',
                      'estimatedDeposit', 'cansCollected', 'actualBottleDeposit',
                      'canDriveFee', 'autoReconciliationFee', 'totalInvoiceAmount'
                    ], true
                  );
                  blobToFile(exportedCSV, '8323 Reports ' + moment().format("MM-DD-YYYY") + '.csv');
                }
                }
              >
                EXPORT
              </Button>
            </div>
          </div>

          <Grid className={classes.selectFieldsContainer} container spacing={5}>
            <Grid item>
              <Select displayEmpty value={selectedOrganization} onChange={onSelectOrganization}>
                <MenuItem value=''>
                  <em>-Select Collector-</em>
                </MenuItem>
                {
                  organizations && organizations.map(organization => {
                    return (
                      <MenuItem value={organization.name}>{organization.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                views={["year"]}
                clearable
                value={calendarYear}
                placeholder="e.g. 2020"
                onChange={year => onCalendarYearChange(year)}
                maxDateMessage="Chosen year cannot be later than 2100" //Component default
                minDateMessage="Chosen year cannot be earlier than 1900" //Component default
                disableFuture={true}
              />
              {/* <TextField
              type="number"
              placeholder="Enter a Calendar Year"
              value={calendarYear}
              onChange={onCalendarYearChange}
            >
            </TextField> */}
            </Grid>
          </Grid>
          {/* table head */}
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={order}
                    onClick={onSort('name')}
                  >
                    Collector Name
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'title'}
                    direction={order}
                    onClick={onSort('title')}
                  >
                    Campaign Title
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={order}
                    onClick={onSort('date')}
                  >
                    Date
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'canDriveTypeId'}
                    direction={order}
                    onClick={onSort('canDriveTypeId')}
                  >
                    Type
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'driveReconType'}
                    direction={order}
                    onClick={onSort('driveReconType')}
                  >
                    Reconciliation Type
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'totalApproximateCansCollected'}
                    direction={order}
                    onClick={onSort('totalApproximateCansCollected')}
                  >
                    Total Approximate Collected
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'estimatedBottleDeposit'}
                    direction={order}
                    onClick={onSort('estimatedBottleDeposit')}
                  >
                    Estimated Bottle Deposit Received Total
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'cansCollected'}
                    direction={order}
                    onClick={onSort('cansCollected')}
                  >
                    Total Actual Cans Collected
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'actualBottleDeposit'}
                    direction={order}
                    onClick={onSort('actualBottleDeposit')}
                  >
                    Actual Bottle Deposit Received Total
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'canDriveFee'}
                    direction={order}
                    onClick={onSort('canDriveFee')}
                  >
                    Can Drive Fee
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'autoReconciliationFee'}
                    direction={order}
                    onClick={onSort('autoReconciliationFee')}
                  >
                    Auto Reconciliation Fee
                </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={orderBy === 'totalInvoiceAmount'}
                    direction={order}
                    onClick={onSort('totalInvoiceAmount')}
                  >
                    Total Invoice Amount
                </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {/* table body */}
            <TableBody className={classes.tableBody}>
              {
                (filteredArray.length === 0 && (!selectedOrganization && !calendarYear && !searchValue)) ? (

                  <TableRow className={classes.TableRow}>
                    <TableCell colSpan={12} align='center' component="th" scope="row" onClick={() => console.log("Row Click")}>
                      Select an organization, then filter their reconciled drives by calendar year or search term.
                </TableCell>
                  </TableRow>
                ) //return
                  //map

                  :
                  filteredArray.length > 0 ? (
                    stableSort(filteredArray, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((report, index) => {
                        return (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}/*component="th" scope="row"*/>
                              {report.organization?.name || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {report.title || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {moment(report.date).format("MM/DD/YYYY") || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {report.canDriveTypeId === 1 ? "Drop Off" : report.canDriveTypeId === 2 ? "Pick Up" : "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {report.isReconciled ? (parseInt(report.cansCollected) > 0 ? "Manual" : "Automatic") : "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {report.totalApproximateCansCollected || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {/* Estimated Deposit ($0.10 per can in Approx Collected) */}
                              {"$" + roundN(report.estimatedBottleDeposit, 2).toString() || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {/* TotalCansCollected */}
                              {report.cansCollected || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {/* Actual Bottle Deposit */}
                              {parseInt(report.cansCollected) > 0 ? roundN((parseInt(report.cansCollected) * 0.10), 2) : "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {/* CanDriveFee (10% of actual bottle deposit) */}
                              {"$" + roundN(report.canDriveFee, 2).toString() || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {/* Auto ReconciliationFee (5% of estimated deposit) */}
                              {report.reconciliationType === "Auto Reconciliation" ? "$" + roundN(report.autoReconciliationFee, 2).toString() : "$0.00" || "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {/* TotalInvoiceAmount Sum of auto recon and can drive fee */}
                              {report.isReconciled ?
                                "$" + roundN(report.totalInvoiceAmount, 2).toString() :
                                ""
                              }
                            </TableCell>
                          </TableRow>
                        ) //return
                      }) //map
                  )
                    : (filteredArray.length === 0) && (selectedOrganization || calendarYear || searchValue) ? (
                      <TableCell className={classes.tableCell}
                        colSpan={12}
                        align='center'
                      >
                        NO RESULTS FOUND
                      </TableCell>

                    )
                      : null
              }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={reports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper >
      </div >
    </MuiPickersUtilsProvider>
  )
};

const mapStateToProps = state => {
  return {
    //Get the 8323s from state 
    organizations: state.organizations.list,
    canDrives: state.canDrives.list,
    reports: state.reports.list,
  }
}

export default compose(
  connect(mapStateToProps, { get8323Reports, getOrganizations, getCanDrives }), withStyles(styles))(EightThreeTwoThreeReports);
