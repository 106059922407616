import { GET_ORGANIZATIONS, UPDATE_ORGANIZATION, GET_ORGANIZATION_BY_ID, CREATE_TRANSACTION, REFUND_TRANSACTION } from '../actions/actionTypes';

const initialState = {
  list: [],
  currentOrganization: {},
  transactions: [],
}

export const organizationReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_ORGANIZATIONS:
      return {
        ...state,
        list: payload
      };

    case GET_ORGANIZATION_BY_ID:
      return {
        ...state,
        currentOrganization: payload
      };

    case UPDATE_ORGANIZATION:
      return {
        list: state.list.map(organization => organization.id === payload.id ? payload : organization),
        currentOrganization: payload
      };

    case CREATE_TRANSACTION:
      return {
        ...state,
        transactions: [...state.transactions, payload]
      };

    case REFUND_TRANSACTION:
      console.log("REFUND_TRANSACTION");
      console.log("Payload:");
      console.log(payload);
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          transactions: state.currentOrganization.transactions.map(transaction => transaction.id === payload.id ? payload : transaction)
        }
      }

    default:
      return state;
  }
}

