import axios from 'axios';
import baseAction from './baseAction';
import {
  CREATE_TRANSACTION,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_BY_ID,
  UPDATE_ORGANIZATION,
  REFUND_TRANSACTION
} from './actionTypes';



export const getOrganizations = () => dispatch => {
  baseAction.get("organizations").then(res => {
    dispatch({
      type: GET_ORGANIZATIONS,
      payload: res.data
    });
  }).catch(err => { });
};

export const getOrganizationById = id => dispatch => {
  baseAction.get(`organizations/${id}`).then(res => {
    dispatch({
      type: GET_ORGANIZATION_BY_ID,
      payload: res.data
    });
  }).catch(err => { });
};

export const updateOrganization = organization => dispatch => {
  baseAction.put("organizations", organization).then(res => {
    dispatch({
      type: UPDATE_ORGANIZATION,
      payload: res.data
    });
  }).catch(err => { });
};

// TODO: Integrate this with the API.
export const createTransaction = (transaction) => (dispatch) => {
  baseAction.post("organizations", transaction).then(res => {
    dispatch({
      type: CREATE_TRANSACTION,
      payload: res.data
    });
  }).catch(err => { });
};

//THIS ACTION IS FOR USE WITHOUT THE API (DELETE LATER) - KG
export const createTransactionNoApi = (transaction) => (dispatch) => {
  dispatch({
    type: CREATE_TRANSACTION,
    payload: transaction
  })
};

//TODO: PUT THIS IN ITS OWN FILE
export const emailAvailable = async email => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + "account/email_available?email=" + email);
    const { taken } = res.data;

    if (taken)
      return "The email entered is already in use.";

    else
      return undefined;
  } catch { }
}

export const downloadBusinessLicense = (organizationId, fileName) => {
  baseAction.get(`organizations/${organizationId}/license`, {
    responseType: 'blob'
  }).then(res => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }).catch(err => { });
}

export const refundTransaction = transactionId => dispatch => {
  baseAction.post(`transactions/${transactionId}/refund`).then(res => {
    dispatch({ type: REFUND_TRANSACTION, payload: res.data });
  }).catch(err => { console.log(err) });
}
