import axios from 'axios';
import baseAction from './baseAction';
import {
  CREATE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_BY_ID,
  UPDATE_NOTIFICATION,
} from './actionTypes';

export const createNotification = (notification) => (dispatch) => {
  baseAction
    .post('notifications', notification)
    .then((res) => {
      dispatch({
        type: CREATE_NOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const cancelNotification = (notification) => (dispatch) => {
  baseAction
    .post('notifications/cancel', notification)
    .then((res) => {
      dispatch({
        type: CREATE_NOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const getNotifications = () => (dispatch) => {
  baseAction
    .get('notifications')
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const getNotificationById = (id) => (dispatch) => {
  baseAction
    .get(`notifications/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATION_BY_ID,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const updateNotification = (notification) => (dispatch) => {
  baseAction
    .put('notifications', notification)
    .then((res) => {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {});
};
