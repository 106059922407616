import React, { useState, useEffect } from 'react';
import { getNotificationById } from '../../redux/actions/pushNotificationsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Avatar,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useHistory } from 'react-router-dom';
import NotficationCreateEditViewDialog from './NotificationCreateEditViewDialog';
import moment from 'moment';

// styles
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10%',
  },
  tableHeader: {
    flexShrink: 0,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    background: '#19bfe7',
    marginRight: '8px',
  },
  title: {
    '& span': {
      color: 'gray',
    },
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    margin: '0 16px',
  },
  search: {
    width: '60%',
  },
  menu: {
    overflowX: 'none',
    overflowY: 'none',
  },
  tableRow: {
    '&:hover': {
      background: 'rgb(245, 245, 245)',
      cursor: 'pointer',
    },
  },
};

function NotificationsTable(props) {
  const { classes } = props;
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(props.notifications);
  }, [props.notifications]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogExistingNotification, setDialogExistingNotification] = useState(
    false
  );

  // table sorting functionality
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const onSort = (property) => (event) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const onCreateNotification = () => {
    setDialogExistingNotification(false);
    setDialogOpen(true);
  };

  // table functions
  const handleRowClick = (notification) => {
    onViewNotification(notification.id);
  };

  // pagination functions
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDialogClose = () => {
    setDialogExistingNotification(false);
    setDialogOpen(false);
  };

  const onViewNotification = (notificationId) => {
    props.getNotificationById(notificationId);
    setDialogExistingNotification(true);
    setDialogOpen(true);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <div className={classes.tableHeader}>
          <div className={classes.titleContainer}>
            <Avatar className={classes.avatar}>
              <NotificationsIcon />
            </Avatar>
            <Typography variant='h6' className={classes.title}>
              Push Notifications
              {Array.isArray(notifications) && notifications.length > 0 ? (
                <span>{` (${notifications.length})`}</span>
              ) : (
                ''
              )}
            </Typography>
          </div>

          <div className={classes.actionsContainer}>
            <Tooltip title='Create new notification'>
              <IconButton
                color='primary'
                onClick={onCreateNotification}
                className={classes.action}
              >
                <AddCircle />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* table head */}
        <Table className={classes.notificationsTable}>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={order}
                  onClick={onSort('title')}
                >
                  Notification Title
                </TableSortLabel>
              </TableCell>

              <TableCell align='left'>
                <TableSortLabel
                  active={orderBy === 'message'}
                  direction={order}
                  onClick={onSort('message')}
                >
                  Notification Message
                </TableSortLabel>
              </TableCell>

              <TableCell align='left'>
                <TableSortLabel
                  active={orderBy === 'sendOn'}
                  direction={order}
                  onClick={onSort('sendOn')}
                >
                  Send On
                </TableSortLabel>
              </TableCell>

              <TableCell align='left'>
                <TableSortLabel
                  active={orderBy === 'isActive'}
                  direction={order}
                  onClick={onSort('isActive')}
                >
                  Active
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {/* table body */}
          <TableBody>
            {stableSort(notifications, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((notification, index) => {
                return (
                  <TableRow className={classes.tableRow} key={notification.id}>
                    <TableCell
                      component='th'
                      scope='row'
                      onClick={() => handleRowClick(notification)}
                    >
                      {notification.title}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => handleRowClick(notification)}
                    >
                      {notification.message}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => handleRowClick(notification)}
                    >
                      {moment(notification.sendOn).format('MM/DD/YYYY hh:mm A')}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => handleRowClick(notification)}
                    >
                      {notification.isActive ? 'Y' : 'N'}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={notifications.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <NotficationCreateEditViewDialog
          existingNotification={dialogExistingNotification}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: state.pushNotifications.list,
  };
};

const mapDispatchToProps = {
  getNotificationById,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(NotificationsTable);
