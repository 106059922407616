import baseAction from './baseAction';
import { GET_CAN_DRIVES } from './actionTypes';

export const getCanDrives = () => dispatch => {
  baseAction.get("candrives").then(res => {
    dispatch({
      type: GET_CAN_DRIVES,
      payload: res.data
    });
  }).catch(err => { });
};