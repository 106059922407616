import axios from 'axios';
import { store } from '../store';
import { LOGOUT_USER } from './actionTypes';

const baseAction = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/admin"
});

baseAction.interceptors.request.use(async (config) => {
  const auth_token = localStorage.getItem("auth_token");
  config.headers.Authorization = `Bearer ${auth_token}`;
  return config;
}, error => {
  return Promise.reject(error);
});

baseAction.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    if (status === 401 || status === 403) {
      store.dispatch({ type: LOGOUT_USER, payload: null });
    }
    return Promise.reject(error);
  }
)

export default baseAction;
