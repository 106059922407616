export const LOGIN_USER = 'LOGIN_USER';
export const LOGGED_IN_USER = 'LOGGED_IN_USER';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_USER = 'LOGOUT_USER';

export const CREATE_USER = 'CREATE_USER';
export const GET_USERS = 'GET USERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const UPDATE_USER = 'UPDATE_USER';

// export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const REFUND_TRANSACTION = 'REFUND_TRANSACTION';

export const GET_CAN_DRIVES = 'GET_CAN_DRIVES';

export const GET_8323s = 'GET_8323s';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CANCEL_NOTIFICATION = 'CANCEL_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET NOTIFICATIONS';
export const GET_NOTIFICATION_BY_ID = 'GET_NOTIFICATION_BY_ID';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
