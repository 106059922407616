import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { authReducer } from './authReducer';
import { organizationReducer } from './organizationReducer';
import { notificationReducer } from './notificationReducer';
import { canDrivesReducer } from './canDrivesReducer';
import { reportReducer } from './reportReducer';
import { pushNotificationReducer } from './pushNotificationReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  organizations: organizationReducer,
  canDrives: canDrivesReducer,
  notification: notificationReducer,
  reports: reportReducer,
  pushNotifications: pushNotificationReducer,
});
