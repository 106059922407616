// react
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// material ui
import {
  Button, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography,
  InputBase, Select, MenuItem, Table, TableHead, TableRow,
  withStyles,
  TableCell,
  TableBody
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';

//components

//icons

// styles
const styles = {
  paper: {
    minHeight: '450px',
    maxHeight: '700px',
    minWidth: '700px',

    '@media only screen and (max-width: 700px)': {
      borderRadius: 'unset',
      minHeight: '100%',
      minWidth: '100%'
    }
  },

  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderBottom: '1px solid lightgray'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },

  dialogActionsContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between'
  }
}

function AttachmentsDialog(props) {
  const { classes } = props;
  const [organization, setOrganization] = useState("Placeholder Org");
  const [attachments, setAttachments] = useState(["Attachment 1", "Attachment 2", "Attachment 3", "Attachment 4", "Attachment 5",]);

  const handleClose = () => {
    props.onClose();
  }

  const onExit = () => {
    console.log("Exit");
  }

  return (
    <Dialog open={props.open} classes={{ paper: classes.paper }} onExit={onExit}>
      <div className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitle}>
          <Typography variant="h6"> {organization}</Typography>
        </div>
      </div>

      <DialogContent>
        <Table>
          <TableHead>
            <TableBody>
              {
                attachments.map((attachment, index) => {
                  return (<TableRow>{index + 1}. {attachment}</TableRow>)
                })
              }
            </TableBody>
          </TableHead>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>

    </Dialog>
  )
}

export default withStyles(styles)(AttachmentsDialog);