// react
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// material ui
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Grid, IconButton, Input, InputAdornment,
  Tooltip, Typography, withStyles
} from '@material-ui/core/';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import queryString from 'query-string';

//redux
import { changePassword } from '../../redux/actions/authActions';
import { connect } from 'react-redux';
import { compose } from 'redux';

// styles
const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logoContainer: {
    display: 'flex',
    padding: '16px'
  },
  logo: {
    margin: '0 auto',
  },
  card: {
    width: "500px",
  },
  cardInput: {
    marginTop: "16px",
  }
}

function ChangePasswordDialog(props) {
  const { classes } = props;
  const currentToken = localStorage.getItem("auth_token");
  // const [params, setParams] = useState(queryString.parse(props.location.search));
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPasswords, setShowPasswords] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const onPasswordChange = e => {
    setCurrentPassword(e.target.value);
    setError(false);
  }

  const onNewPasswordChange = e => {
    setNewPassword(e.target.value);
    setError(false);
  }

  const onConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    setError(false);
  }

  const onChangePassword = e => {
    // e.preventDefault();
    if (!newPassword && !confirmPassword) {
      setErrorMessage("New Password and Confirm Password cannot be empty.");
      setError(true);
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("New Password and Confirm Password do not match.");
      setError(true);
    } else if (currentPassword === newPassword) {
      setErrorMessage("The new password cannot be your current password.");
      setError(true);
    } else { // todo: the password needs to be at least 8 characters with 1 capital, 1 lowercase and 1 digit
      setError(false);
      changePassword(currentPassword, newPassword);
      props.onClose();
      // props.resetPassword(params.e, params.t, password);

    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Grid container>
          {error ? <Typography color="error" variant="body2">{errorMessage}</Typography> : null}
          <Grid item xs={12}>
            <Input
              error={error}
              type={showPasswords ? "text" : "password"}
              placeholder="Password *"
              name="currentPassword"
              value={currentPassword}
              onChange={onPasswordChange}
              fullWidth={true}
              className={classes.cardInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswords(!showPasswords)}
                  >
                    {showPasswords ?
                      <Tooltip title="Hide password">
                        <Visibility />
                      </Tooltip> :
                      <Tooltip title="Show password">
                        <VisibilityOff />
                      </Tooltip>}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              error={error}
              type={showPasswords ? "text" : "password"}
              placeholder="New password"
              name="newPassword"
              value={newPassword}
              onChange={onNewPasswordChange}
              fullWidth={true}
              className={classes.cardInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswords(!showPasswords)}
                  >
                    {showPasswords ?
                      <Tooltip title="Hide password">
                        <Visibility />
                      </Tooltip> :
                      <Tooltip title="Show password">
                        <VisibilityOff />
                      </Tooltip>}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              error={error}
              type={showPasswords ? "text" : "password"}
              placeholder="Confirm password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={onConfirmPasswordChange}
              fullWidth={true}
              className={classes.cardInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswords(!showPasswords)}
                  >
                    {showPasswords ?
                      <Tooltip title="Hide password">
                        <Visibility />
                      </Tooltip> :
                      <Tooltip title="Show password">
                        <VisibilityOff />
                      </Tooltip>}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={props.onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" /*onClick={props.onClose}*/ onClick={onChangePassword}>
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    userEmail: state.auth.sub,
  }
}
export default compose(connect(mapStateToProps, { changePassword }), withStyles(styles))(ChangePasswordDialog)
