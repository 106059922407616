import React from 'react';
import { Redirect } from 'react-router-dom';
import { registerTeamMember } from '../../redux/actions/authActions';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import queryString from 'query-string';

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    padding: '16px',
  },
  logo: {
    margin: '0 auto',
  },
  card: {
    width: '500px',
  },
  cardInput: {
    marginTop: '16px',
  },
};

// {URL}/team_member_setup?f={}&l={}&e={}&p={}&t={}
// f = firstName
// l = lastName
// e = email
// p = phoneNumber
// t = token
function TeamMemberRegistrationPage(props) {
  const { classes } = props;
  const [params] = React.useState(queryString.parse(props.location.search));
  const [teamMember, setTeamMember] = React.useState({
    firstName: params.f,
    lastName: params.l,
    email: encodeURI(params.e).replace(/%20/g, '+'),
    phoneNumber: params.p,
  });
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [showPasswords, setShowPasswords] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);

  const onChange = (e) => {
    setTeamMember({
      ...teamMember,
      [e.target.name]: e.target.value,
    });
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordError(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!password && !confirmPassword) {
      setErrorMessage('Password and confirm password cannot be empty.');
      setPasswordError(true);
    } else if (password !== confirmPassword) {
      setErrorMessage('Password and confirm password do not match.');
      setPasswordError(true);
    } else if (password.length < 8) {
      setErrorMessage('Password must be be at least 8 characters.');
      setPasswordError(true);
    } else {
      setPasswordError(false);
      registerTeamMember(
        teamMember.firstName,
        teamMember.lastName,
        teamMember.email,
        teamMember.phoneNumber,
        password,
        params.t // params.t is the token
      );
      setRedirect(true);
    }
  };

  return (
    <React.Fragment>
      {redirect ? <Redirect to='/confirm_email' /> : null}
      <Card className={classes.card}>
        <div className={classes.logoContainer}>
          <div className={classes.logo}>
            <img
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt='Can Drives'
              width='200'
            />
            <Typography align='center'>Team Member Registration</Typography>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <CardContent>
            <Grid container spacing={2}>
              {/* First name */}
              <Grid item xs={12}>
                <TextField
                  label='First name'
                  name='firstName'
                  value={teamMember.firstName}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>

              {/* Last name */}
              <Grid item xs={12}>
                <TextField
                  label='Last name'
                  name='lastName'
                  value={teamMember.lastName}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>

              {/* Email (disabled) */}
              <Grid item xs={12}>
                <TextField
                  label='Email'
                  name='email'
                  value={teamMember.email}
                  // onChange={onChange}
                  fullWidth
                  required
                  disabled
                />
              </Grid>

              {/* Phone number */}
              <Grid item xs={12}>
                <TextField
                  label='Phone number'
                  name='phoneNumber'
                  value={teamMember.phoneNumber}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>

              {/* Password */}
              <Grid item xs={12}>
                {passwordError ? (
                  <Typography color='error' variant='body2'>
                    {errorMessage}
                  </Typography>
                ) : null}
                <Input
                  type={showPasswords ? 'text' : 'password'}
                  placeholder='Password'
                  value={password}
                  onChange={onPasswordChange}
                  fullWidth={true}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPasswords(!showPasswords)}
                      >
                        {showPasswords ? (
                          <Tooltip title='Hide password'>
                            <Visibility />
                          </Tooltip>
                        ) : (
                          <Tooltip title='Show password'>
                            <VisibilityOff />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={passwordError}
                  required
                />
              </Grid>

              {/* Confirm password */}
              <Grid item xs={12}>
                <Input
                  type={showPasswords ? 'text' : 'password'}
                  placeholder='Confirm password'
                  value={confirmPassword}
                  onChange={onConfirmPasswordChange}
                  fullWidth={true}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPasswords(!showPasswords)}
                      >
                        {showPasswords ? (
                          <Tooltip title='Hide password'>
                            <Visibility />
                          </Tooltip>
                        ) : (
                          <Tooltip title='Show password'>
                            <VisibilityOff />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={passwordError}
                  required
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container>
              <Button
                color='primary'
                fullWidth={true}
                type='submit'
                variant='contained'
              >
                Register
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Card>
    </React.Fragment>
  );
}

export default withStyles(styles)(TeamMemberRegistrationPage);
