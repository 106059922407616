// react
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

// material ui
import { CssBaseline, Container, withStyles } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

// components
// import { StripeProvider } from 'react-stripe-elements';
import { connect } from "react-redux";
import { compose } from "redux";
import LoginPage from "../login/LoginPage";
import ResetPasswordPage from "../resetPassword/ResetPasswordPage";
import ConfirmEmailPage from "../confirmEmail/ConfirmEmailPage";
import TeamMemberRegistrationPage from "../confirmEmail/TeamMemberRegistrationPage";
import Navigation from "../_common/Navigation";
import OrganizationPage from "../organizations/OrganizationsPage";
import EightThreeTwoThreeReportsPage from "../8323reports/EightThreeTwoThreeReportsPage";
import UsersPage from "../users/UsersPage";
// import FinancialReports from '../FinancialReports';
import ReconciliationPage from "../reconciliation/ReconciliationPage";
import NotFoundPage from "../../notfoundpage/NotFoundPage";
import DonorActivityPage from "../donorActivity/DonorActivityPage";
import NotificationsPage from "../notifications/NotificationsPage";

// styles
import { appTheme } from "./appTheme";
import SnackbarNotification from "../_common/SnackbarNotification";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
  },
  body: {
    flexGrow: 1,
    display: "flex",
    // route components are immediate children of this container and will always be centered on the screen.
    justifyContent: "center",
    alignItems: "center",
  },
};

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function App(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      {/* <StripeProvider apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx"> */}
      <MuiThemeProvider theme={appTheme}>
        <CssBaseline />
        <SnackbarNotification />
        <Router>
          {props.isAuthenticated ? <Navigation /> : null}
          <Switch>
            <Route path="/confirm_email" component={ConfirmEmailPage} />
            <Container className={classes.body} maxWidth="lg">
              <Route exact path="/login" component={LoginPage} />
              <Route path="/reset_password" component={ResetPasswordPage} />
              <Route
                path="/register_team_member"
                component={TeamMemberRegistrationPage}
              />
              <ProtectedRoute
                exact
                path="/users/:id"
                component={DonorActivityPage}
                isAuthenticated={props.isAuthenticated}
              />
              <ProtectedRoute
                exact
                path="/users"
                component={UsersPage}
                isAuthenticated={props.isAuthenticated}
              />
              <ProtectedRoute
                exact
                path="/"
                component={() => <Redirect to="/organizations" />}
                isAuthenticated={props.isAuthenticated}
              />
              <ProtectedRoute
                exact
                path="/organizations"
                component={OrganizationPage}
                isAuthenticated={props.isAuthenticated}
              />
              <ProtectedRoute
                exact
                path="/reconciliation"
                component={ReconciliationPage}
                isAuthenticated={props.isAuthenticated}
              />
              {/* <ProtectedRoute exact path="/financialreports" component={FinancialReports} isAuthenticated={props.isAuthenticated} /> */}
              <ProtectedRoute
                exact
                path="/8323reports"
                component={EightThreeTwoThreeReportsPage}
                isAuthenticated={props.isAuthenticated}
              />
              <ProtectedRoute
                exact
                path="/notifications"
                component={NotificationsPage}
                isAuthenticated={props.isAuthenticated}
              />
            </Container>
            <ProtectedRoute
              component={NotFoundPage}
              isAuthenticated={props.isAuthenticated}
            />
          </Switch>
        </Router>
      </MuiThemeProvider>
      {/* </StripeProvider> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default compose(connect(mapStateToProps), withStyles(styles))(App);
