// react
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getOrganizations } from '../../redux/actions/organizationActions';
import { downloadBusinessLicense, updateOrganization } from '../../redux/actions/organizationActions';

// material ui
import {
  Button, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Typography,
  Select, MenuItem, FormLabel, Link,
  withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

//components
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';


// styles
const styles = {
  paper: {
    minHeight: '450px',
    maxHeight: '600px',
    minWidth: '700px',

    '@media only screen and (max-width: 700px)': {
      borderRadius: 'unset',
      minHeight: '100%',
      minWidth: '100%'
    }
  },

  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderBottom: '1px solid lightgray'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },

  dialogActionsContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between'
  },

  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  billingIssueText: {
    paddingTop: 12,
    fontSize: 12,
    color: 'red',
  }
}

const initialOrganization = {
  //id: 0,
  name: "Initial",
  address: {
    id: 1,
    name: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
  },
  notes: "",
  businessLicenseFileName: "",
  url: "",
  status: "",
  expirationDate: "",
  isApproved: "",
  approvalRejectionNotes: "",
  // businessLicenseFileName: "",
  primaryUser: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
  }
}

function OrganizationEditViewDialog(props) {
  const { classes } = props;
  const [organization, setOrganization] = useState(initialOrganization);
  const [isEditingOrganization, setIsEditingOrganization] = useState(true);
  const [expirationAccepted, setExpirationAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [billingIssue, setBillingIssue] = useState(false);

  useEffect(() => {
    if (props.existingOrganization === true) {
      setOrganization(props.organization);

    } else {
      setOrganization(initialOrganization)
    }
  }, [props.organization]);

  useEffect(() => {
    if (props.organization.isApproved === true) {
      setExpirationAccepted(true);
    }
    else {
      setExpirationAccepted(false);
    }
  }, [props.organization])

  useEffect(() => {
    //check if there's a billing issue
    if (props.organization.lastTransaction != null) {
      if (props.organization.lastTransaction.status !== "succeeded") {
        setBillingIssue(true);
      }
    }
  }, [props.organization])

  const onOrganizationChange = e => {
    e.target.name === "isApproved" && e.target.value === true ?

      setOrganization({
        ...organization,
        [e.target.name]: e.target.value,
        expirationDate: moment().add(1, "year")
      })
      :
      setOrganization({
        ...organization,
        [e.target.name]: e.target.value
      })
  }

  const onPrimaryUserChange = e => {
    setOrganization({
      ...organization,
      primaryUser: {
        ...organization.primaryUser,
        [e.target.name]: e.target.value
      }
    })
  }

  //This needs to be its own function because the values are further down than object level.
  const onAddressChange = e => {
    setOrganization({
      ...organization,
      address: {
        ...organization.address,
        [e.target.name]: e.target.value
      }
    })
  }

  const onApprovalChange = e => {
    console.log(e.target.value);
    switch (e.target.value) {
      case null:
        setOrganization({
          ...organization,
          isApproved: null,
          expirationDate: null
        })
        setIsRejected(false);
        break;

      case true:
        setOrganization({
          ...organization,
          isApproved: true,
          expirationDate: moment().add(1, "year").format()
        })
        setIsRejected(false);
        break;

      case false:
        setOrganization({
          ...organization,
          isApproved: false,
          expirationDate: null
        });
        setIsRejected(true);
        break;

      default:
    }
  }

  const onExpirationDateChange = (date) => {
    setOrganization({
      ...organization,
      expirationDate: moment(date).utc().format()
    })
  }

  const onEditOrganization = () => {
    setIsEditingOrganization(true);
  }

  const onClose = () => {
    setIsEditingOrganization(false);
    props.onClose();

  }

  // dialog cleanup
  const onExit = () => {
    setOrganization(initialOrganization);
  }

  const onSave = async e => {
    e.preventDefault();
    props.existingOrganization ? props.updateOrganization(organization) : props.createOrganization(organization);
    onClose();
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Dialog open={props.open} classes={{ paper: classes.paper }} key={props.organization} onExit={onExit}>
        {/* dialog title */}
        <div className={classes.dialogTitleContainer}>
          <div className={classes.dialogTitle}>
            <Typography variant="h6">{props.existingOrganization ? `Edit Collector -  ${organization.name}` : "Create Collector"}</Typography>
          </div>
          <div>
            <IconButton size="small" onClick={props.onClose}><Close /></IconButton>
          </div>
        </div>

        <form id="organizationForm" onSubmit={onSave}>
          {/* dialog content */}
          <DialogContent>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item justify="flex-start" className={classes.gridItem1}>
                <Grid container spacing={2} /*justify="flex-start"*/>
                  {/* Start Text Fields */}
                  {/* Organization Name */}
                  <Grid item sm={6} xs={12}>
                    <TextField InputLabelProps={{ shrink: organization.name }} required name="name" value={organization.name} label="Collector Name" onChange={onOrganizationChange} fullWidth />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField InputLabelProps={{ shrink: organization.primaryUser }} required helperText="e.g. example@example.com" inputProps={{ pattern: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+" }}
                      name="email" value={organization.primaryUser ? organization.primaryUser.email : ""} label="Email" onChange={onPrimaryUserChange}
                      fullWidth
                    />
                  </Grid>

                  {/* First Name */}
                  <Grid item sm={6} xs={12}>
                    <TextField InputLabelProps={{ shrink: organization.primaryUser }} required name="firstName" value={organization.primaryUser ? organization.primaryUser.firstName : ""} label="First Name" onChange={onPrimaryUserChange} fullWidth />
                  </Grid>

                  {/* Last Name */}
                  <Grid item sm={6} xs={12}>
                    <TextField InputLabelProps={{ shrink: organization.primaryUser }} required name="lastName" value={organization.primaryUser ? organization.primaryUser.lastName : ""} label="Last Name" onChange={onPrimaryUserChange} fullWidth />
                  </Grid>

                  {/* phone */}
                  <Grid item sm={6} xs={12}>
                    <NumberFormat InputLabelProps={{ shrink: organization.primaryUser }} required customInput={TextField} format="+1 (###) ###-####" mask="_" inputProps={{ pattern: "^\\+1[ ]?\\(?\\d{3}\\)[ ]?[- ]?\\d{3}[- ]?\\d{4}$" }} /*       ^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$            */
                      name="phoneNumber" value={organization.primaryUser ? organization.primaryUser.phoneNumber : ""} label="Phone number" onChange={onPrimaryUserChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <br /><br />
                <Grid item sm={6} xs={12}>
                  <FormLabel>Address</FormLabel>
                </Grid>
                {
                  organization.address ?

                    <Grid container spacing={2}>
                      {/* address */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: organization.address.streetAddress !== null }} required name="streetAddress" value={organization.address.streetAddress} label="Street Address" onChange={onAddressChange} fullWidth />
                      </Grid>

                      {/* city */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: organization.address.city !== null }} required name="city" value={organization.address.city} label="City" onChange={onAddressChange} fullWidth />
                      </Grid>

                      {/* state */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: organization.address.state !== null }} required helperText="e.g. MI" name="state" value={organization.address.state} label="State" onChange={onAddressChange} fullWidth />
                      </Grid>

                      {/* zip */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: organization.address.zipCode !== null }} required helperText="e.g. 12345" inputProps={{ pattern: "^[0-9]{5}(?:-[0-9]{4})?$" }} name="zipCode" value={organization.address.zipCode} label="Zip" onChange={onAddressChange} fullWidth />
                      </Grid>
                    </Grid>
                    : <h3>No Address Data Found</h3>
                }

                <br /><br />

                <Grid container spacing={2} /*justify="flex-start"*/>
                  {/* url */}
                  <Grid item sm={6} xs={12}>
                    <TextField /*type="url"*/ InputLabelProps={{ shrink: organization.website }} required helperText="e.g. example.com, www.example.com, http://www.example.com"
                      name="website" value={organization.website} label="URL" onChange={onOrganizationChange} fullWidth
                      inputProps={{ pattern: "^(http:\\/\\/www\\.|https:\\/\\/www\.|http:\\/\\/|https:\\/\\/)?[a-z0-9 |A-Z0-9 ]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?" }}
                    />
                  </Grid>

                  {/* attachments */}
                  <Grid item sm={6} xs={12}>
                    <Typography>Attachments</Typography>
                    {organization.businessLicenseFileName ?
                      <Typography className={classes.link}>
                        <Link onClick={() => downloadBusinessLicense(organization.id, organization.businessLicenseFileName)}>
                          {organization.businessLicenseFileName}
                        </Link>
                      </Typography> : null}
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Typography>Approve/Reject</Typography>
                    <Select name="isApproved"
                      value={organization.isApproved}
                      onChange={(event) => {
                        //If the organization is approved in Redux
                        if (props.organization.isApproved) {
                          setExpirationAccepted(true);
                        }
                        else {
                          event.target.value === true ? setExpirationAccepted(true) : setExpirationAccepted(false);
                          setOrganization({
                            ...organization,
                            isApproved: event.target.value
                          })
                          onApprovalChange(event);
                        }
                      }}
                      disabled={props.organization.isApproved}
                      displayEmpty
                      fullWidth>
                      <MenuItem value={null}>Pending</MenuItem>
                      <MenuItem value={true}>Approved</MenuItem>
                      <MenuItem value={false}>Rejected</MenuItem>
                    </Select>
                    {
                      billingIssue ?
                        <Typography className={classes.billingIssueText}>There is an issue with this organization's credit card.</Typography>
                        : null
                    }

                  </Grid>

                  {/* approval/rejection notes */}
                  <Grid item sm={6} xs={12}>
                    <TextField InputLabelProps={{ shrink: organization.externalNotes }} multiline variant="outlined" rows={5}
                      label="Approval/Rejection Notes" name="externalNotes" value={organization.externalNotes} onChange={onOrganizationChange} fullWidth />
                  </Grid>

                  {/* expiration Date */}
                  <Grid item sm={6} xs={12}>
                    <Typography>Expiration Date</Typography>
                    <KeyboardDatePicker
                      // required
                      helperText={expirationAccepted ?
                        `Please note that once collectors are approved, the approval cannot be 
                        changed until after the expiration date has been reached.`
                        : `e.g. ${moment().add(1, "day").format("MM/DD/YYYY")}`}
                      variant="inline"
                      format="MM/DD/YYYY"
                      minDate={Date(moment().add(1, "day").format("MM/DD/YYYY"))}
                      value={moment(organization.expirationDate).format("MM/DD/YYYY") === "01/01/0001" ? moment().format("MM/DD/YYYY") : organization.expirationDate}
                      onChange={(date) => {
                        date !== null && date.isAfter(moment()) ? setExpirationAccepted(true) : setExpirationAccepted(false)
                        onExpirationDateChange(date);
                      }}
                      onAccept={(date) => {
                        setExpirationAccepted(true);
                      }}
                      disabled={isRejected}
                      invalidDateMessage="Invalid Date"
                    />
                  </Grid>

                  {/* notes */}
                  <Grid item sm={6} xs={12}>
                    <TextField InputLabelProps={{ shrink: organization.internalNotes }} multiline variant="outlined" name="internalNotes" rows={5} value={organization.internalNotes} label="Internal Notes" onChange={onOrganizationChange} fullWidth />
                  </Grid>

                </Grid>
              </Grid> {/* End Grid item 1 */}
            </Grid> {/* End Parent Grid */}
          </DialogContent >
          {/* dialog actions */}
          < DialogActions >
            <div className={classes.dialogActionsContainer}>
              <Fragment>
                <div>
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={onClose}
                  >
                    Cancel
                </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  // onClick={onSave}
                  >
                    Save
                </Button>

                </div>
              </Fragment>
            </div>
          </DialogActions >
        </form>
      </Dialog >
    </MuiPickersUtilsProvider>
  )
}

OrganizationEditViewDialog.propTypes = {
  existingContact: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

OrganizationEditViewDialog.defaultProps = {
  existingContact: false
}

const mapStateToProps = state => {
  return {
    organization: state.organizations.currentOrganization
  }
}

export default compose(
  connect(mapStateToProps, { updateOrganization, getOrganizations }),
  withStyles(styles)
)(OrganizationEditViewDialog);
