// react
import React, { useState, useEffect } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getOrganizationById, downloadBusinessLicense } from '../../redux/actions/organizationActions';
// material ui
import {
  Avatar, FormControl, Button, IconButton, Input,
  InputAdornment, Menu, MenuItem,
  Paper, Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow, TableSortLabel,
  Tooltip, Typography, withStyles, InputLabel,
} from '@material-ui/core';
import {
  AddCircle, Person, Search,
  Business
} from '@material-ui/icons';

// components
import moment from 'moment';
import OrganizationEditViewDialog from './OrganizationEditViewDialog';
import AttachmentsDialog from './AttachmentsDialog';
import BillingDialog from './BillingDialog';
import { exportToCsvBlob } from '../_common/spudExportCSV/array';
import { blobToFile } from '../_common/spudExportCSV/blob';

//icons
import AttachmentIcon from '@material-ui/icons/Attachment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10%'
  },

  tableHeader: {
    flexShrink: 0,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    background: '#2780e3',
    marginRight: '8px'
  },
  title: {
    '& span': {
      color: 'gray'
    }
  },

  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    margin: '0 16px'
  },
  search: {
    width: '60%'
  },

  menu: {
    overflowX: 'none',
    overflowY: 'none'
  },

  tableRow: {
    '&:hover': {
      background: 'rgb(245, 245, 245)',
      cursor: 'pointer'
    }
  },

  tableCell: {
    overflowWrap: "break-word",
    textWrap: "break-word",
    wordBreak: "break-word",
  }
};

function OrganizationManagementTable(props) {
  const { classes } = props;
  const [organizations, setOrganizations] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');

  // anchorEl, setAnchorEl and menuOpen are used for the create new contact/company menu.
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  // rowsPerPage and setRowsPerPage are used for the TablePagination component.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogExistingOrganization, setDialogExistingOrganization] = useState(false);
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);

  //  These state values are used for search functionality
  const [searchValue, setSearchValue] = useState("");
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (props.organizations !== undefined) {
      setOrganizations(props.organizations);
    }
  }, [props.organizations]); //Only re-run the effect if props.organizations changes!

  useEffect(() => {
    filterOrganizations(searchValue);
  }, [searchValue]); //Only re-run the effect if searchValue changes!

  function onSearchChange(e) {
    setSearchValue(e.target.value);
    // filterOrganizations(searchValue);
  }

  function filterOrganizations(searchValue) {
    let originalArray = organizations;

    if (originalArray) {
      var filteredArray = originalArray.filter((item, index) => {
        let result = true;

        result = item.name.toLowerCase().includes(searchValue.toLowerCase())
        if (!result) return result;

        return result;
      });
      setFilteredArray(filteredArray);
    }
  }

  // table sorting functionality
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const onSort = property => event => {
    //onRequestSort(event, property);
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  // menu functions
  const handleMenuOpen = e => {
    setAnchorEl(e.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const onCreateOrganization = () => {
    handleMenuClose();
    setDialogExistingOrganization(false);
    setDialogOpen(true);
  }

  // table functions
  const handleRowClick = (organization) => {
    onViewOrganization(organization.id);
  }

  // pagination functions
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDialogClose = () => {
    setDialogExistingOrganization(true);
    setDialogOpen(false);
  };


  const handleAttachmentsClose = () => {
    setAttachmentsOpen(false);
  }

  const onViewOrganization = organizationId => {
    props.getOrganizationById(organizationId);
    setDialogExistingOrganization(true);
    setDialogOpen(true);
  };

  const openBilling = organizationId => {
    props.getOrganizationById(organizationId);
    setBillingOpen(true);
  }

  const handleBillingClose = () => {
    setBillingOpen(false);
  }

  const handleBillingSave = () => {
    setBillingOpen(false);
    // props.saveBilling(); //Todo Add billing save
  }

  const getIsApprovedLabel = isApproved => {
    if (isApproved === true)
      return "Approved";

    else if (isApproved === false)
      return "Rejected";

    else if (isApproved === null)
      return "Pending";
  }

  return (
    <div className={classes.root}>
      <Paper>
        <div className={classes.tableHeader}>
          <div className={classes.titleContainer}>
            <Avatar className={classes.avatar}>
              {/* <Person /> */}
              <Business />
            </Avatar>
            <Typography variant="h6" className={classes.title}>
              Collector Management
              {Array.isArray(organizations) && organizations.length > 0 ?
                <span>{` (${organizations.length})`}</span> :
                ""}
            </Typography>

          </div>
          {/* Search Bar */}
          <div className={classes.searchContainer}>
            <FormControl fullWidth className={classes.search}>
              <InputLabel htmlFor="standard-adornment-amount">Search</InputLabel>
              <Input
                name="searchValue"
                id="standard-adornment-amount"
                value={searchValue}
                // placeholder="Search"
                onChange={onSearchChange}
                startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
              />
            </FormControl>
          </div>
          <div className={classes.actionsContainer}>

            <Button variant="outlined" color="primary" onClick={() => {
              let formattedOrganizations = [];
              organizations.forEach(org => {
                let data = org;
                if (org.primaryUser !== null) {
                  let email = org.primaryUser.email;
                  let phone = org.primaryUser.phoneNumber;
                  data =
                  {
                    ...data,
                    email: org.primaryUser.email,
                    phoneNumber: org.primaryUser.phoneNumber
                  }
                  formattedOrganizations.push(data);
                }
              });

              let exportedCSV = exportToCsvBlob(formattedOrganizations,
                [
                  'name', 'email', 'phoneNumber', 'website'
                ], true);

              blobToFile(exportedCSV, 'Organizations ' + moment().format("MM-DD-YYYY") + '.csv');
            }}
            >EXPORT
            </Button>
            <Menu
              id="menu-appbar"
              className={classes.menu}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={menuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={onCreateOrganization}>Create New Organization</MenuItem>
            </Menu>
          </div>
        </div>

        {/* table head */}
        <Table className={classes.organizationsTable}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={order}
                  onClick={onSort('name')}
                >
                  Collector Name
                </TableSortLabel>
              </TableCell>

              <TableCell >
                Billing
              </TableCell>

              <TableCell >
                <TableSortLabel
                  active={orderBy === 'organization.primaryUser.email'}
                  direction={order}
                  onClick={onSort('organization.primaryUser.email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>

              <TableCell >
                <TableSortLabel
                  active={orderBy === 'organization.primaryUser.phoneNumber'}
                  direction={order}
                  onClick={onSort('organization.primaryUser.phoneNumber')}
                >
                  Phone
                </TableSortLabel>
              </TableCell>

              <TableCell >
                <TableSortLabel
                  active={orderBy === 'website'}
                  direction={order}
                  onClick={onSort('website')}
                >
                  URL
                </TableSortLabel>
              </TableCell>

              <TableCell >
                <TableSortLabel
                  active={orderBy === 'attachments'}
                  direction={order}
                  onClick={onSort('attachments')}
                >
                  Attachments
                </TableSortLabel>
              </TableCell>

              <TableCell >
                <TableSortLabel
                  active={orderBy === 'isApproved'}
                  direction={order}
                  onClick={onSort('isApproved')}
                >
                  Status
                </TableSortLabel>
              </TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredArray.length <= 0 && searchValue.length < 1 ? (
                stableSort(organizations, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((organization, index) => {
                    return (
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell} onClick={() => handleRowClick(organization)}>
                          {organization.name}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => openBilling(organization.id)}>
                            <MonetizationOnIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.primaryUser ? organization.primaryUser.email : "None"}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.primaryUser ? organization.primaryUser.phoneNumber : "None"}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.website}
                        </TableCell>
                        <TableCell >

                          {organization.businessLicenseFileName !== null ? <Button onClick={() => downloadBusinessLicense(organization.id, organization.businessLicenseFileName)}><AttachmentIcon /></Button> : "None"}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.isApproved === true ? "Approved" : organization.isApproved === false ? "Rejected" : "Pending"}
                        </TableCell>
                        {/* <TableCell onClick={() => handleRowClick(organization)}> */}
                        {/* Assuming this is being given space before it's being formatted, resulting in extra space? */}
                        {/* {moment(organization.expirationDate).format("MM/DD/YYYY") < moment("01/01/0010").format("MM/DD/YYYY") ? "N/A" : moment(organization.expirationDate).format("MM-DD-YYYY")} */}
                        {/* {moment(organization.expirationDate).format("MM-DD-YYYY")} */}
                        {/* </TableCell> */}

                        {/* <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.isActive.toString()}
                        </TableCell> */}
                      </TableRow>
                    ) //return
                  }) //map
              )
                :
                stableSort(filteredArray, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((organization, index) => {
                    return (
                      <TableRow className={classes.tableRow}>
                        <TableCell scope="row" onClick={() => handleRowClick(organization)} className={{ whitespace: "normal", wordWrap: "break-word" }}>
                          {organization.name}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => openBilling(organization.id)}>
                            <MonetizationOnIcon ></MonetizationOnIcon>
                          </IconButton>
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.primaryUser ? organization.primaryUser.email : "None"}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.primaryUser ? organization.primaryUser.phoneNumber : "None"}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {organization.website}
                        </TableCell>
                        <TableCell >
                          {organization.businessLicenseFileName !== null ? <Button onClick={() => downloadBusinessLicense(organization.id, organization.businessLicenseFileName)}><AttachmentIcon /></Button> : "None"}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(organization)}>
                          {getIsApprovedLabel(organization.isApproved)}
                        </TableCell>
                        {/* <TableCell onClick={() => handleRowClick(organization)}>
                          {moment(organization.expirationDate).format("MM/DD/YYYY") < moment("01/01/0010").format("MM/DD/YYYY") ? "N/A" : moment(organization.expirationDate).format("MM-DD-YYYY")}
                        </TableCell> */}

                        {/* <TableCell onClick={() => handleRowClick(organization)} >
                          {organization.isActive.toString()}
                        </TableCell> */}
                      </TableRow>
                    ) //return
                  }) //map
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={organizations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {/* OrganizationEditViewDialog Attachments Dialog, and Billing Dialog are always mounted! */}
        {/* organization dialog */}
        <OrganizationEditViewDialog
          existingOrganization={dialogExistingOrganization}
          open={dialogOpen}
          onClose={handleDialogClose}
        />

        {/* Attachments Dialog */}
        <AttachmentsDialog // TODO? Add organization prop with getOrganizationById to retrieve attachments?
          open={attachmentsOpen}
          onClose={handleAttachmentsClose}
        />

        {/* Billing Dialog */}
        <BillingDialog
          open={billingOpen}
          onClose={handleBillingClose}
          onSave={handleBillingSave}
        />
      </Paper >
    </div >
  )
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations.list
  }
}

export default compose(
  connect(mapStateToProps, { getOrganizationById }),
  withStyles(styles)
)(OrganizationManagementTable);
