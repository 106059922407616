// react
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// redux
import { resetPassword } from '../../redux/actions/authActions';

// material ui
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import queryString from 'query-string';

// styles
const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    padding: '16px',
  },
  logo: {
    margin: '0 auto',
  },
  card: {
    width: '500px',
  },
  cardInput: {
    marginTop: '16px',
  },
};

function ResetPasswordPage(props) {
  const { classes } = props;
  const history = useHistory();
  const [params, setParams] = useState(
    queryString.parse(props.location.search)
  );
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPasswords, setShowPasswords] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setError(false);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!password && !confirmPassword) {
      setErrorMessage('Password and confirm password cannot be empty.');
      setError(true);
    } else if (password !== confirmPassword) {
      setErrorMessage('Password and confirm password do not match.');
      setError(true);
    } else {
      // todo: the password needs to be at least 8 characters with 1 capital, 1 lowercase and 1 digit
      setError(false);
      resetPassword(params.e, params.t, password);
      window.location.replace('https://candrives.com/');
    }
  };

  return (
    <Card className={classes.card}>
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <img
            src={process.env.PUBLIC_URL + '/logo.png'}
            alt='CanDrive'
            width='200'
          />
          <Typography align='center'>Reset Password</Typography>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <CardContent>
          <Grid container>
            {error ? (
              <Typography color='error' variant='body2'>
                {errorMessage}
              </Typography>
            ) : null}
            <Grid item xs={12}>
              <Input
                error={error}
                type={showPasswords ? 'text' : 'password'}
                placeholder='Password *'
                name='password'
                value={password}
                onChange={onPasswordChange}
                fullWidth={true}
                className={classes.cardInput}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      {showPasswords ? (
                        <Tooltip title='Hide password'>
                          <Visibility />
                        </Tooltip>
                      ) : (
                        <Tooltip title='Show password'>
                          <VisibilityOff />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                error={error}
                type={showPasswords ? 'text' : 'password'}
                placeholder='Confirm password'
                name='confirmPassword'
                value={confirmPassword}
                onChange={onConfirmPasswordChange}
                fullWidth={true}
                className={classes.cardInput}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPasswords(!showPasswords)}
                    >
                      {showPasswords ? (
                        <Tooltip title='Hide password'>
                          <Visibility />
                        </Tooltip>
                      ) : (
                        <Tooltip title='Show password'>
                          <VisibilityOff />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container>
            <Button
              variant='contained'
              color='primary'
              fullWidth={true}
              type='submit'
            >
              RESET PASSWORD
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
}

export default withStyles(styles)(ResetPasswordPage);
