// react
import React, { useState, useEffect } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCanDrives } from '../../redux/actions/canDrivesActions';
// material ui
import {
  Avatar, FormControl, Button, IconButton, Input,
  InputAdornment, Menu, MenuItem,
  Paper, Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow, TableSortLabel,
  Tooltip, Typography, withStyles, InputLabel
} from '@material-ui/core';
import {
  AddCircle, Person, Search,
  AttachMoney
} from '@material-ui/icons';

// components
import moment from 'moment';
import { exportToCsvBlob } from '../_common/spudExportCSV/array';
import { blobToFile } from '../_common/spudExportCSV/blob';

//icons

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10%'
  },

  tableParent: {
    marginTop: '10%'
  },

  tableHeader: {
    flexShrink: 0,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    background: '#2780e3',
    marginRight: '8px'
  },
  title: {
    '& span': {
      color: 'gray'
    }
  },

  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    margin: '0 16px'
  },
  search: {
    width: '60%'
  },

  menu: {
    overflowX: 'none',
    overflowY: 'none'
  },

  aboveTable: {
    flexShrink: 0,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
  },

  tableHead: {
    // border: 'solid 1px green',

  },

  table: {
    // overflowX: 'auto'
  },

  tableBody: {

  },

  tableRow: {
    '&:hover': {
      background: 'rgb(245, 245, 245)',
    }
  },

  tableCell: {
    // border: 'dashed 1px blue',
    paddingRight: '5px',
    paddingLeft: '5px',
    textOverflow: 'ellipsis'
    // whiteSpace: 'nowrap',
    // wordBreak: 'break-all'
  },

  highlightedTableCell: {
    paddingRight: '5px',
    paddingLeft: '15px',
    textOverflow: 'ellipsis',
    color: 'red',
    fontWeight: '900'
  }
};

function ReconciliationTable(props) {
  const { classes } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');
  //Changing this value will affect what percentage the Manuaally reconciled amount must be =< of the Total Approx Amount
  const [reconciliationFlag, setreconciliationFlag] = React.useState(0.5);

  // rowsPerPage and setRowsPerPage are used for the TablePagination component.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  //  These state values are used for search functionality
  const [searchValue, setSearchValue] = useState("");
  const [filteredArray, setFilteredArray] = useState([]);


  const [canDrives, setCanDrives] = React.useState([]);

  useEffect(() => {
    props.getCanDrives();
  }, [null]);

  useEffect(() => {
    if (props.canDrives !== undefined) {
      let groupedPickups = [];
      let adHocPickups = props.canDrives.filter(row => row.canDriveTypeId === 3);
      let canDrives = props.canDrives.filter(row => row.canDriveTypeId !== 3);

      adHocPickups.forEach(pickup => {
        const { date } = pickup;
        if (pickup.isReconciled) {
          pickup.title = "Ad Hoc Pickups " + moment(date).format("MM/DD/YYYY");
        } else {
          pickup.date = null;
          pickup.startTime = null;
          pickup.endTime = null;
        }

        if (groupedPickups.some(item => moment(item.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY") && item.organization?.name === pickup?.organization?.name)) {
          groupedPickups.map(groupedPickup => {
            if (moment(groupedPickup.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY")) {
              groupedPickup.totalApproximateCansCollected += pickup.totalApproximateCansCollected;
              groupedPickup.organizationTotalApproximateCansCollected += pickup.totalApproximateCansCollected;
            }
          })
        } else {
          groupedPickups.push(pickup);
        }
      })

      setCanDrives([...groupedPickups, ...canDrives]);
    }
  }, [props.canDrives]); //Only re-run the effect if props.canDrives changes!

  useEffect(() => {
    filterCanDrives(searchValue);
  }, [searchValue]); //Only re-run the effect if searchValue changes!

  function onSearchChange(e) {
    setSearchValue(e.target.value);
  }

  function filterCanDrives(searchValue) {
    let originalArray = canDrives; // props.canDrives

    if (originalArray) {
      var filteredArray = originalArray.filter((item, index) => {
        let result = true;
        let date = moment(searchValue, "MM/DD/YYYY");
        let itemDate = moment(item.date).format("MM/DD/YYYY");

        if (!date.isValid()) {
          result = item.organization && item.organization?.name.toLowerCase().includes(searchValue.toLowerCase())
          if (!result) return result;
        }

        if (date.isValid()) {
          date = date.format("MM/DD/YYYY");
          result = itemDate.includes(date);
          if (!result) return result;
        }
        return result;
      });
      setFilteredArray(filteredArray);
    }
  }

  // table sorting functionality
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const onSort = property => event => {
    //onRequestSort(event, property);
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  // pagination functions
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className={classes.root}>
      <Paper >
        <div className={classes.tableHeader}>
          <div className={classes.titleContainer}>
            <Avatar className={classes.avatar}>
              {/* <Person /> */}
              <AttachMoney />
            </Avatar>
            <Typography variant="h6" className={classes.title}>
              Reconciliation
            </Typography>
          </div>
          {/* Search Bar */}
          <div className={classes.searchContainer}>
            <FormControl fullWidth className={classes.search}>
              <InputLabel htmlFor="standard-adornment-amount">Search</InputLabel>
              <Input
                name="searchValue"
                id="standard-adornment-amount"
                value={searchValue}
                // placeholder="Search"
                onChange={onSearchChange}
                startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
              />
            </FormControl>
          </div>
          <div className={classes.actionsContainer}>
            <Button variant="outlined" color="primary" onClick={() => {
              let formattedCanDrives = [];
              canDrives.forEach(canDrive => {
                let data = canDrive;
                let organizationName = canDrive.organization?.name;
                data =
                {
                  ...data,
                  organizationName,

                }
                formattedCanDrives.push(data);
              });

              let exportedCSV = exportToCsvBlob(formattedCanDrives,
                [
                  'organizationName', 'title', 'description', 'date', 'startTime', 'endTime', 'canDriveTypeId', 'isCompleted', 'driveReconType', 'totalApproximateCansCollected', 'cansCollected'
                ], true
              );
              blobToFile(exportedCSV, 'Reconciliation ' + moment().format("MM-DD-YYYY") + '.csv');
            }}
            >EXPORT</Button>
          </div>
        </div>

        {/* table head */}
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'organizationName'}
                  direction={order}
                  onClick={onSort('organizationName')}
                >
                  Collector Name
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={order}
                  onClick={onSort('title')}
                >
                  Campaign Title
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'description'}
                  direction={order}
                  onClick={onSort('description')}
                >
                  Description
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'date'}
                  direction={order}
                  onClick={onSort('date')}
                >
                  Date
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'startTime'}
                  direction={order}
                  onClick={onSort('startTime')}
                >
                  Start Time
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'endTime'}
                  direction={order}
                  onClick={onSort('endTime')}
                >
                  End Time
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'canDriveTypeId'}
                  direction={order}
                  onClick={onSort('canDriveTypeId')}
                >
                  Type
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'driveStatus'}
                  direction={order}
                  onClick={onSort('driveStatus')}
                >
                  Status
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'driveReconType'}
                  direction={order}
                  onClick={onSort('driveReconType')}
                >
                  Reconciliation Type
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'approxCollected'}
                  direction={order}
                  onClick={onSort('approxCollected')}
                >
                  Total Approximate Collected
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'approxCollected'}
                  direction={order}
                  onClick={onSort('approxCollected')}
                >
                  Donor Total Approximate Collected
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'manualReconAmount'}
                  direction={order}
                  onClick={onSort('manualReconAmount')}
                >
                  Manually Reported Cans Collected
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {/* table body */}
          <TableBody className={classes.tableBody}>
            {
              filteredArray.length <= 0 && searchValue.length < 1 ? (
                stableSort(canDrives, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((canDrive, index) => {
                    return (
                      <TableRow className={classes.tableRow} >
                        <TableCell component="th" scope="row">
                          {canDrive.organization ? canDrive.organization.name : ""}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.title}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.description}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.date ? moment(canDrive.date).format("MM/DD/YYYY") : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.startTime ? moment.utc(canDrive.startTime, 'YYYY-MM-DDTHH:mm').local().format("MM/DD/YYYY hh:mm A") : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.endTime ? moment.utc(canDrive.endTime, 'YYYY-MM-DDTHH:mm').local().format("MM/DD/YYYY hh:mm A") : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.canDriveTypeId === 1 ? "Drop Off" : canDrive.canDriveTypeId === 2 ? "Pick Up" : canDrive.canDriveTypeId === 3 ? "Ad Hoc Pickup" : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.isReconciled ? "Completed" : "Active"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {
                            canDrive.isReconciled ? (parseInt(canDrive.cansCollected) > 0 ? "Manual" : "Automatic") : "N/A"
                          }
                        </TableCell>
                        <TableCell className={
                          canDrive.isReconciled && canDrive.organizationTotalApproximateCansCollected <= (canDrive.totalApproximateCansCollected * 0.5) ?
                            classes.highlightedTableCell :
                            classes.tableCell
                        }>
                          {canDrive.organizationTotalApproximateCansCollected}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.totalApproximateCansCollected}
                        </TableCell>
                        <TableCell className={
                          canDrive.isReconciled && canDrive.cansCollected > 0 && canDrive.cansCollected < (canDrive.totalApproximateCansCollected * 0.5) ?
                            classes.highlightedTableCell :
                            classes.TableCell
                        }>
                          {canDrive.cansCollected || "N/A"}
                        </TableCell>
                      </TableRow>
                    ) //return
                  }) //map
              )
                :
                stableSort(filteredArray, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((canDrive, index) => {
                    return (
                      <TableRow className={classes.tableRow} >
                        <TableCell component="th" scope="row">
                          {canDrive.organization?.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.title}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.description}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.date ? moment(canDrive.date).format("MM/DD/YYYY") : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.startTime ? moment.utc(canDrive.startTime, 'YYYY-MM-DDTHH:mm').local().format("MM/DD/YYYY hh:mm A") : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.endTime ? moment.utc(canDrive.endTime, 'YYYY-MM-DDTHH:mm').local().format("MM/DD/YYYY hh:mm A") : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.canDriveTypeId === 1 ? "Drop Off" : canDrive.canDriveTypeId === 2 ? "Pick Up" : canDrive.canDriveTypeId === 3 ? "Ad Hoc Pickup" : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {(canDrive.isCompleted && canDrive.isReconciled) == false ? "Active" : (canDrive.isCompleted || canDrive.isReconciled) ? "Completed" : "N/A"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {
                            canDrive.isReconciled ? (parseInt(canDrive.cansCollected) > 0 ? "Manual" : "Automatic") : "N/A"
                          }
                        </TableCell>
                        <TableCell className={
                          canDrive.isReconciled && canDrive.organizationTotalApproximateCansCollected <= (canDrive.totalApproximateCansCollected * 0.5) ?
                            classes.highlightedTableCell :
                            classes.tableCell
                        }>
                          {canDrive.organizationTotalApproximateCansCollected}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {canDrive.totalApproximateCansCollected}
                        </TableCell>
                        <TableCell className={
                          canDrive.isReconciled && canDrive.cansCollected > 0 && canDrive.cansCollected < (canDrive.totalApproximateCansCollected * 0.5) ?
                            classes.highlightedTableCell :
                            classes.TableCell
                        }>
                          {canDrive.cansCollected || "N/A"}
                        </TableCell>
                      </TableRow>
                    ) //return
                  }) //map
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={canDrives.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper >
    </div >
  )
}

const mapStateToProps = state => {
  return {
    //Get the CanDrives from state 
    canDrives: state.canDrives.list
  }
}

export default compose(
  connect(mapStateToProps, { getCanDrives }),
  withStyles(styles)
)(ReconciliationTable);
