import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Paper, Table, TableBody, TableCell,
  TableHead, TableRow
} from '@material-ui/core';

import intersectionBy from 'lodash/intersectionBy';

import { getOrganizations } from '../../redux/actions/organizationActions';
import { withStyles } from '@material-ui/styles';

const styles = {
  paperChild: {
    minWidth: "300px",
    minHeight: "100px",
  }
}

function UserOrganizationList(props) {
  const { classes } = props;
  const [userOrganizations, SetUserOrganizations] = useState([]);

  useEffect(() => {
    SetUserOrganizations(intersectionBy(props.currentUser.organizations, props.organizations, 'name'));
  }, [props.currentUser]); //Only re-run the effect if props.currentUser changes!

  useEffect(() => {
    props.getOrganizations(); //TODO: There's gotta be a better way. I feel getting all organizations here is a waste of resources. - KG

  }, [null]); //Only re-run the effect if props.organizations changes!

  return (
    <div>
      <Paper>
        <div className={classes.paperChild}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Collector Name</TableCell>
                <TableCell align="center">Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>


              {
                userOrganizations.length > 0 ?
                  userOrganizations.map(organization => (
                    <TableRow>
                      <TableCell align="center">{organization.name}</TableCell>
                      <TableCell align="center">{organization.role}</TableCell>
                    </TableRow>
                  ))
                  :
                  <TableRow>
                    <TableCell align="center">N/A</TableCell>
                    <TableCell align="center">N/A</TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations.list,
    currentUser: state.users.currentUser
  }
}

export default compose(connect(mapStateToProps, { getOrganizations }), withStyles(styles))(UserOrganizationList);
