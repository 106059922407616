// react
import React, { useState } from 'react';
import PropTypes from 'prop-types';

//redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { logoutUser } from '../../redux/actions/authActions';

// material ui
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';

// components
import NavigationItem from './NavigationItem';
import ChangePasswordDialog from './ChangePasswordDialog';

// styles
const styles = {
  toolbar: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    padding: '0 16px',
  },
  accountControl: {
    marginLeft: 'auto',
    color: 'inherit',
    // textTransform: `none` will display the button with the capitalization of the text as it
    // is typed between the <Button> tags. by default, button text is all capitalized. 🤯
    textTransform: 'none',
  },
};

function Navigation(props) {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <ChangePasswordDialog open={dialogOpen} onClose={handleDialogClose} />
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <img src={props.logo} alt={props.branding} height='75' />
          <NavigationItem title='Collector Management' to='/organizations' />
          <NavigationItem title='Reconciliation' to='/reconciliation' />
          <NavigationItem title='User Management' to='/users' />
          {/* <NavigationItem title="Financial Reports" to="/financialreports" /> */}
          <NavigationItem title='8323 Reports' to='/8323reports' />
          <NavigationItem title='Push Notifications' to='/notifications' />
          <Button
            variant='text'
            onClick={handleMenuOpen}
            className={classes.accountControl}
          >
            <Person />
            {props.fullName}
          </Button>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleDialogOpen}>Change password</MenuItem>
            <MenuItem onClick={props.logoutUser}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Navigation.defaultProps = {
  branding: 'CanDrive',
  logo: process.env.PUBLIC_URL + '/logo.png',
};

Navigation.propTypes = {
  branding: PropTypes.string,
  logo: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    fullName: state.auth.fullName,
  };
};

export default compose(
  connect(mapStateToProps, { logoutUser }),
  withStyles(styles)
)(Navigation);
