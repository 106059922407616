// styles
import { createMuiTheme } from '@material-ui/core/styles';

export const appTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#47cbeb",
      main: "#19bfe7",
      dark: "#1185a1",
      contrastText: "#fff"
    },
    secondary: {
      light: "#f26f60",
      main: "#ef4c39",
      dark: "#a73527",
      contrastText: "#fff"
    }
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
  }
})
