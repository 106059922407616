// react
import React, { useEffect } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// material ui
import { withStyles } from '@material-ui/core';

// components
import ReconciliationTable from './ReconciliationTable';

// styles
const styles = {
  root: {
    position: 'relative',
    // width: '100%',
    height: '800px',
    // overflow: 'auto'
  },
};

function ReconciliationPage(props) {
  const { classes } = props;
  // useEffect(() => {
  //   props.getOrganizations();
  // }, [null]);
  return (
    <div className={classes.root}>
      <ReconciliationTable />
    </div>
  )
};

export default compose(
  connect(null),
  withStyles(styles)
)(ReconciliationPage);
