import axios from 'axios';
import baseAction from './baseAction';
import { CREATE_USER, GET_USERS, GET_USER_BY_ID, UPDATE_USER } from './actionTypes';

export const createUser = user => dispatch => {
  baseAction.post("users", user).then(res => {
    dispatch({
      type: CREATE_USER,
      payload: res.data
    });
  }).catch(err => { });
}

export const getUsers = () => dispatch => {
  baseAction.get("users").then(res => {
    dispatch({
      type: GET_USERS,
      payload: res.data
    });
  }).catch(err => { });
};

export const getUserById = id => dispatch => {
  baseAction.get(`users/${id}`).then(res => {
    dispatch({
      type: GET_USER_BY_ID,
      payload: res.data
    })
  }).catch(err => { });
};

export const updateUser = user => dispatch => {
  baseAction.put("users", user).then(res => {
    dispatch({
      type: UPDATE_USER,
      payload: res.data
    });
  }).catch(err => { });
};

export const emailAvailable = async email => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + "/account/email_available?email=" + email);
    const { taken } = res.data;

    if (taken)
      return "The email entered is already in use.";

    else
      return undefined;
  } catch { }
}
