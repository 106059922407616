import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { getCanDrives } from '../../redux/actions/canDrivesActions';
import { getOrganizations } from '../../redux/actions/organizationActions';
import { getUserById } from '../../redux/actions/userActions';
import DonorActivityTable from './DonorActivityTable';

const styles = {
  root: {
    position: 'relative',
    width: '100%',
    height: '800px',
    overflow: 'auto'
  }
};

const DonorActivityPage = (props) => {
  const { classes } = props;

  useEffect(() => {
    props.getCanDrives();
    props.getOrganizations();
  }, [null])

  useEffect(() => {
    props.getUserById(props.match.params.id)
  }, [props.match.params.id])

  return (
    <div className={classes.root}>
      <DonorActivityTable />
    </div>
  )
}

const mapDispatchToProps = {
  getCanDrives,
  getOrganizations,
  getUserById
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(DonorActivityPage);
