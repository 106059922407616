import React, { useState, useEffect } from 'react';
import { forgotPassword } from '../../redux/actions/authActions';
import { getUserById } from '../../redux/actions/userActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Avatar, IconButton, Paper, Table,
  TableBody, TableCell, TableHead, TablePagination,
  TableRow, TableSortLabel, Tooltip, Typography,
  withStyles
} from '@material-ui/core';
import { AddCircle, Email, Person, MonetizationOn } from '@material-ui/icons';
import UserCreateEditViewDialog from './UserCreateEditViewDialog';
import { useHistory } from 'react-router-dom';

// styles
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10%'
  },
  tableHeader: {
    flexShrink: 0,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    background: '#19bfe7',
    marginRight: '8px'
  },
  title: {
    '& span': {
      color: 'gray'
    }
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    margin: '0 16px'
  },
  search: {
    width: '60%'
  },
  menu: {
    overflowX: 'none',
    overflowY: 'none'
  },
  tableRow: {
    '&:hover': {
      background: 'rgb(245, 245, 245)',
      cursor: 'pointer'
    }
  }
};

function UsersTable(props) {
  const { classes } = props;
  const history = useHistory();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]); // Only re-run the effect if props.users changes!


  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');
  // rowsPerPage and setRowsPerPage are used for the TablePagination component.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogExistingUser, setDialogExistingUser] = useState(false);

  // table sorting functionality
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const onSort = property => event => {
    //onRequestSort(event, property);
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const onCreateUser = () => {
    setDialogExistingUser(false);
    setDialogOpen(true);
  }

  // table functions
  const handleRowClick = user => {
    onViewUser(user.id);
  }

  // pagination functions
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDialogClose = () => {
    setDialogExistingUser(false);
    setDialogOpen(false);
  };

  const onViewUser = userId => {
    props.getUserById(userId);
    setDialogExistingUser(true);
    setDialogOpen(true);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <div className={classes.tableHeader}>
          <div className={classes.titleContainer}>
            <Avatar className={classes.avatar}>
              <Person />
            </Avatar>
            <Typography variant="h6" className={classes.title}>
              Users
              {Array.isArray(users) && users.length > 0 ?
                <span>{` (${users.length})`}</span> : ""}
            </Typography>
          </div>

          <div className={classes.actionsContainer}>
            <Tooltip title="Create new user">
              <IconButton
                color="primary"
                onClick={onCreateUser}
                className={classes.action}
              >
                <AddCircle />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* table head */}
        <Table className={classes.usersTable}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'firstName'}
                  direction={order}
                  onClick={onSort('firstName')}
                >
                  Name
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={order}
                  onClick={onSort('email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'phoneNumber'}
                  direction={order}
                  onClick={onSort('phoneNumber')}
                >
                  Phone
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'isAdmin'}
                  direction={order}
                  onClick={onSort('isAdmin')}
                >
                  Admin
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'isAppUser'}
                  direction={order}
                  onClick={onSort('isAppUser')}
                >
                  App User
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'isActive'}
                  direction={order}
                  onClick={onSort('isActive')}
                >
                  Active
                </TableSortLabel>
              </TableCell>

              <TableCell align="center" />

              {/* This is the send password reset link column */}
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          {/* table body */}
          <TableBody>
            {
              stableSort(users, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => {
                  return (
                    <TableRow className={classes.tableRow}>
                      <TableCell component="th" scope="row" onClick={() => handleRowClick(user)}>
                        {user.fullName}
                      </TableCell>
                      <TableCell align="left" onClick={() => handleRowClick(user)}>
                        {user.email}
                      </TableCell>
                      <TableCell align="left" onClick={() => handleRowClick(user)}>
                        {user.phoneNumber}
                      </TableCell>
                      <TableCell align="left" onClick={() => handleRowClick(user)}>
                        {user.isAdmin ? "Y" : "N"}
                      </TableCell>
                      <TableCell align="left" onClick={() => handleRowClick(user)}>
                        {user.isAppUser ? "Y" : "N"}
                      </TableCell>
                      <TableCell align="left" onClick={() => handleRowClick(user)}>
                        {user.isActive ? "Y" : "N"}
                      </TableCell>
                      <TableCell align="center">
                        {user.donationsCount > 0 ?
                          <IconButton onClick={() => history.push("/users/" + user.id)}>
                            <MonetizationOn />
                          </IconButton> : null}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={`Send password reset email to ${user.email}`}>
                          <IconButton onClick={() => forgotPassword(user.email)}>
                            <Email />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <UserCreateEditViewDialog
          existingUser={dialogExistingUser}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      </Paper >
    </div >
  )
}

const mapStateToProps = state => {
  return {
    users: state.users.list
  }
}

const mapDispatchToProps = {
  getUserById,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UsersTable);
