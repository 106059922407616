import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = {
  background: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, .6)'
  }
}

function Spinner(props) {
  const { classes } = props;

  return (
    <div className={classes.background}>
      <CircularProgress size={70} />
    </div>
  )
}

export default withStyles(styles)(Spinner);
