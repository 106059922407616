// react
import React, { useEffect } from 'react';

// redux
import { getOrganizations } from '../../redux/actions/organizationActions';
import { compose } from 'redux';
import { connect } from 'react-redux';

// material ui
import { withStyles } from '@material-ui/core';

// components
import OrganizationManagementTable from './OrganizationManagementTable';

// styles
const styles = {
  root: {
    position: 'relative',
    width: '100%',
    height: '800px',
    // overflow: 'auto'
  }
};

function OrganizationsPage(props) {
  const { classes } = props;
  useEffect(() => {
    props.getOrganizations();
  }, [null]);
  return (
    <div className={classes.root}>
      <OrganizationManagementTable />
    </div>
  )
};

export default compose(
  connect(null, { getOrganizations }),
  withStyles(styles)
)(OrganizationsPage);
