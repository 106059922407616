import { SHOW_NOTIFICATION, LOGOUT_USER } from '../actions/actionTypes';

const initialState = {
  message: null,
  id: null
};

export const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_NOTIFICATION:
      return payload;

    case LOGOUT_USER:
      return initialState

    default:
      return state;
  }
};
