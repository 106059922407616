// react
import React, { useState } from 'react'

import { forgotPassword } from '../../redux/actions/authActions';

// material ui
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, TextField
} from '@material-ui/core';

function ForgotPasswordDialog(props) {
  const [email, setEmail] = useState("");

  const onChange = e => {
    setEmail(e.target.value);
  }

  const onSubmit = e => {
    e.preventDefault();
    forgotPassword(email);
    props.handleClose();
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <form onSubmit={onSubmit}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your email address and we will send you a link to reset your password.
            </DialogContentText>
            <TextField
              autoFocus
              label="Email"
              type="email"
              value={email}
              onChange={onChange}
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="secondary">
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="contained">
              Send Reset Link
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

export default ForgotPasswordDialog;
