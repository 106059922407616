import { GET_CAN_DRIVES } from '../actions/actionTypes';

const initialState = {
  list: [],
};

export const canDrivesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CAN_DRIVES:
      return {
        ...state,
        list: payload
      };

    default:
      return state;
  }
}

