import {
  CREATE_NOTIFICATION,
  CANCEL_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_BY_ID,
  UPDATE_NOTIFICATION,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  currentNotification: {},
};

export const pushNotificationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CREATE_NOTIFICATION:
      return {
        list: [...state.list, payload],
        currentNotification: payload,
      };

    case CANCEL_NOTIFICATION:
      return {
        list: state.list.map((notification) =>
          notification.id === payload.id ? payload : notification
        ),
        currentNotification: payload,
      };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        list: payload,
      };

    case GET_NOTIFICATION_BY_ID:
      return {
        ...state,
        currentNotification: payload,
      };

    case UPDATE_NOTIFICATION:
      return {
        list: state.list.map((notification) =>
          notification.id === payload.id ? payload : notification
        ),
        currentNotification: payload,
      };

    default:
      return state;
  }
};
