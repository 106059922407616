import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  createNotification,
  updateNotification,
  cancelNotification,
} from '../../redux/actions/pushNotificationsActions';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';

const styles = {
  paper: {
    '@media only screen and (max-width: 700px)': {
      borderRadius: 'unset',
      minHeight: '75%',
      minWidth: '100%',
    },
  },

  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderBottom: '1px solid lightgray',
  },

  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },

  dialogActionsContainer: {
    display: 'flex',
    flexGrow: 1,
  },
};

const initialNotification = {
  title: '',
  message: '',
  sendOn: new Date(),
  isActive: true,
};

function NotificationCreateEditViewDialog(props) {
  const { classes } = props;
  const [notification, setNotification] = useState(initialNotification);

  useEffect(() => {
    if (props.existingNotification === true) {
      setNotification(props.notification);
    } else setNotification(initialNotification);
  }, [props.notification]);

  const onNotificationChange = (e) => {
    setNotification({
      ...notification,
      [e.target.name]: e.target.value,
    });
  };

  const onClose = () => {
    props.onClose();
  };

  // dialog cleanup
  const onExit = () => {
    setNotification(initialNotification);
  };

  const onSave = async (e) => {
    e.preventDefault();

    var sendOn = new Date(notification.sendOn);

    notification.sendOn = sendOn;

    props.existingNotification
      ? props.updateNotification(notification)
      : props.createNotification(notification);
    onClose();
  };

  const onCancel = async (e) => {
    e.preventDefault();
    props.cancelNotification(notification);
    onClose();
  };

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.paper }}
      key={props.existingNotification.id}
      onExit={onExit}
    >
      {/* dialog title */}
      <div className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitle}>
          <Typography variant='h6'>
            {props.existingNotification
              ? `Push Notification Edit`
              : 'Push Notification Add'}
          </Typography>
        </div>
        <div>
          <IconButton size='small' onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      </div>

      <form onSubmit={onSave}>
        {/* dialog content */}
        <DialogContent style={{ marginBottom: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                InputLabelProps={{ shrink: notification.title }}
                required
                name='title'
                value={notification.title}
                label='Title'
                onChange={onNotificationChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                InputLabelProps={{ shrink: notification.message }}
                required
                name='message'
                value={notification.message}
                label='Message'
                onChange={onNotificationChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name='sendOn'
                value={notification.sendOn}
                label='Send On'
                onChange={onNotificationChange}
                fullWidth
                type='datetime-local'
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography variant='body2'>
                (Leave blank to send your notification immediately)
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        {/* dialog actions */}
        <DialogActions>
          <Grid
            container
            className={classes.dialogActionsContainer}
            justify={
              props.existingNotification &&
              new Date(props.notification.sendOn) > new Date() &&
              props.notification.isActive
                ? 'space-between'
                : 'flex-end'
            }
          >
            {props.existingNotification &&
            new Date(props.notification.sendOn) > new Date() &&
            props.notification.isActive ? (
              <Grid item>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={onCancel}
                >
                  Cancel Notification
                </Button>
              </Grid>
            ) : null}

            <Grid item>
              <Button color='secondary' variant='text' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit' color='primary' variant='contained'>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}

NotificationCreateEditViewDialog.propTypes = {
  existingUser: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

NotificationCreateEditViewDialog.defaultProps = {
  existingUser: false,
};

const mapStateToProps = (state) => {
  return {
    currentUserId: state.auth.id,
    notification: state.pushNotifications.currentNotification,
  };
};

const mapDispatchToProps = {
  createNotification,
  updateNotification,
  cancelNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(NotificationCreateEditViewDialog);
