import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { emailAvailable, createUser, updateUser } from '../../redux/actions/userActions';
import {
  Button, Checkbox, Dialog, DialogActions,
  DialogContent, FormControlLabel, Grid, IconButton,
  TextField, Typography, withStyles, FormLabel
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import UserOrganizationList from './UserOrganizationList';

const styles = {
  paper: {
    minHeight: '450px',
    maxHeight: '500px',
    minWidth: '700px',

    '@media only screen and (max-width: 700px)': {
      borderRadius: 'unset',
      minHeight: '100%',
      minWidth: '100%'
    }
  },

  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderBottom: '1px solid lightgray'
  },

  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },

  dialogActionsContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between'
  }
}

const initialUser = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  addresses: [{
    id: "",
    name: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
  }],
  isAppUser: false,
  isAdmin: true,
  isActive: true,
  roles: []
}

function UserCreateEditViewDialog(props) {
  const { classes } = props;
  const [user, setUser] = useState(initialUser);
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (props.existingUser === true)
      setUser(props.user);

    else
      setUser(initialUser)
  }, [props.user]);

  const onUserChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const onUserAddressChange = e => {
    switch (e.target.name) {

      case "name":
        setName(e.target.value);
        break;

      case "streetAddress":
        setStreetAddress(e.target.value);
        break;

      case "state":
        setState(e.target.value);
        break;

      case "city":
        setCity(e.target.value);
        break;

      case "zipCode":
        setZipCode(e.target.value);
        break;

      default:
    }
  }

  const onCheckboxChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.checked,
    })
  }

  const onClose = () => {
    props.onClose();
  }

  // dialog cleanup
  const onExit = () => {
    setUser(initialUser);
  }

  const onSave = async e => {
    e.preventDefault();

    const emailTaken = await emailAvailable(user.email);
    setEmailError(emailTaken);

    if (user.addresses[0] !== undefined && user.addresses[0].streetAddress !== undefined) {
      user.addresses.shift();
    }

    user.addresses.push({
      name,
      streetAddress,
      city,
      state,
      zipCode
    })

    if (!emailTaken && !emailError) {
      props.existingUser ? props.updateUser(user) : props.createUser(user);
      onClose();
    }
  }

  return (
    <Dialog open={props.open} classes={{ paper: classes.paper }} key={props.user.id} onExit={onExit}>
      {/* dialog title */}
      <div className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitle}>
          <Typography variant="h6">{props.existingUser ? `Edit User ${user.fullName}` : "Create user"}</Typography>
        </div>
        <div>
          <IconButton size="small" onClick={props.onClose}><Close /></IconButton>
        </div>
      </div>

      <form onSubmit={onSave}>
        {/* dialog content */}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item justify="flex-start">
              <Grid container spacing={2}>

                {/* First Name */}
                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: user.firstName }}
                    required
                    name="firstName"
                    value={user.firstName}
                    label="First Name"
                    onChange={onUserChange}
                    fullWidth
                  />
                </Grid>

                {/* Last Name */}
                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: user.lastName }}
                    required
                    name="lastName"
                    value={user.lastName}
                    label="Last Name"
                    onChange={onUserChange}
                    fullWidth
                  />
                </Grid>

                {/* email */}
                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: user.email }}
                    required
                    /*inputProps={{ pattern: "^[^\s@]+@[^\s@]+\.[^\s@]+" }}*/ //TODO: This does not seem to account for all cases
                    name="email"
                    value={user.email}
                    label="Email"
                    onChange={onUserChange}
                    fullWidth
                    error={Boolean(emailError)}
                    helperText={emailError}
                  />
                </Grid>

                {/* phone */}
                <Grid item sm={6} xs={12}>
                  <TextField InputLabelProps={{ shrink: user.phoneNumber }} required inputProps={{ pattern: "^((\+|00)?[1-9]{2}|0)[1-9]( ?[0-9]){8}$" }} //TODO; This is not functioning properly
                    name="phoneNumber" value={user.phoneNumber} label="Phone" onChange={onUserChange} fullWidth
                  />
                </Grid>
              </Grid>
              <br />

              <Grid item sm={6} xs={12}>
                <FormLabel>Address</FormLabel>
              </Grid>
              {
                user.addresses[0] && props.existingUser ?
                  <Grid container spacing={2}>
                    {/* Street Adddress */}
                    <Grid item sm={6} xs={12}>
                      <TextField InputLabelProps={{ shrink: user.addresses[0].streetAddress }} name="streetAddress" value={user.addresses[0].streetAddress} label="Street Address" onChange={onUserAddressChange} fullWidth />
                    </Grid>

                    {/* Address Name */}
                    <Grid item sm={6} xs={12}>
                      <TextField InputLabelProps={{ shrink: user.addresses[0].name }} name="name" value={user.addresses[0].name} label="Nickname" onChange={onUserAddressChange} fullWidth />
                    </Grid>

                    {/* Address City */}
                    <Grid item sm={6} xs={12}>
                      <TextField InputLabelProps={{ shrink: user.addresses[0].city }} name="city" value={user.addresses[0].city} label="City" onChange={onUserAddressChange} fullWidth />
                    </Grid>

                    {/* Address State */}
                    <Grid item sm={6} xs={12}>
                      <TextField InputLabelProps={{ shrink: user.addresses[0].state }} name="state" value={user.addresses[0].state} label="State" onChange={onUserAddressChange} fullWidth />
                    </Grid>

                    {/* Address ZipCode */}
                    <Grid item sm={6} xs={12}>
                      <TextField InputLabelProps={{ shrink: user.addresses[0].zipCode }} /*inputProps={{ pattern: "^[0-9]{5}(?:-[0-9]{4})?$" }}*/ name="zipCode" value={user.addresses[0].zipCode} label="Zip Code" onChange={onUserAddressChange} fullWidth />
                    </Grid>
                  </Grid>

                  : !props.existingUser ?
                    <Grid container spacing={2}>
                      {/* Street Adddress */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: streetAddress }} name="streetAddress" value={streetAddress} label="Street Address" onChange={onUserAddressChange} fullWidth />
                      </Grid>

                      {/* Address Name */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: name }} name="name" value={name} label="Nickname" onChange={onUserAddressChange} fullWidth />
                      </Grid>

                      {/* Address City */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: city }} name="city" value={city} label="City" onChange={onUserAddressChange} fullWidth />
                      </Grid>

                      {/* Address State */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: state }} name="state" value={state} label="State" onChange={onUserAddressChange} fullWidth />
                      </Grid>

                      {/* Address ZipCode */}
                      <Grid item sm={6} xs={12}>
                        <TextField InputLabelProps={{ shrink: zipCode }} /*inputProps={{ pattern: "^[0-9]{5}(?:-[0-9]{4})?$" }}*/ name="zipCode" value={zipCode} label="Zip Code" onChange={onUserAddressChange} fullWidth />
                      </Grid>
                      {/* End Text Fields */}
                    </Grid>

                    : <h3>No Address Data Found</h3>
              }

              {/* Start Checkboxes */}
              <Grid container spacing={2}>
                {/* isAdmin */}
                <Grid item sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        name="isAdmin"
                        checked={user.isAdmin}
                        value={user.isAdmin}
                        onChange={onCheckboxChange}
                      />
                    }
                    label="Admin"
                  />
                </Grid>

                {/* isAppUser */}
                <Grid item sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        name="isAppUser"
                        checked={user.isAppUser}
                        value={user.isAppUser}
                        onChange={onCheckboxChange}
                      />
                    }
                    label="App User"
                  />
                </Grid>

                {/* isActive */}
                <Grid item sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isActive"
                        disabled={props.existingUser && props.user.id === props.currentUserId}
                        checked={user.isActive}
                        value={user.isActive}
                        onChange={onCheckboxChange}
                      />
                    }
                    label="Active"
                  />
                </Grid> {/* End Checkboxes ?*/}
              </Grid>
            </Grid> {/* End Grid item 1 */}

            <Grid item justify="flex-end" className={classes.gridItem2}>
              {
                props.existingUser ?
                  <Grid container spacing={2}>
                    <UserOrganizationList />
                  </Grid>
                  : null
              }

            </Grid> {/* End Grid item 2 */}
          </Grid> {/* End Parent Grid */}

        </DialogContent>

        {/* dialog actions */}
        <DialogActions>
          <div className={classes.dialogActionsContainer}>

            {/* the 'save', 'cancel' and 'more/less fields' buttons should only appear when
              the user is editing/creating a contact. */}
            <Fragment>
              <div>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </Fragment> :
        </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

UserCreateEditViewDialog.propTypes = {
  existingUser: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

UserCreateEditViewDialog.defaultProps = {
  existingUser: false
}

const mapStateToProps = state => {
  return {
    currentUserId: state.auth.id,
    user: state.users.currentUser
  }
}

const mapDispatchToProps = {
  createUser,
  updateUser
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(UserCreateEditViewDialog);
