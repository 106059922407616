// react
import React from 'react';

// redux

// material ui

// components

// styles

function NotFoundPage(props) {
  return (
    <h1>Page Not Found</h1>
  )

}

export default (NotFoundPage)