import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Avatar, IconButton, Paper, Table,
  TableBody, TableCell, TableHead, TablePagination,
  TableRow, TableSortLabel, Tooltip, Typography,
  withStyles
} from '@material-ui/core';
import { AddCircle, Email, Person, MonetizationOn } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10%'
  },
  tableHeader: {
    flexShrink: 0,
    flexBasis: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    background: '#19bfe7',
    marginRight: '8px'
  },
  title: {
    '& span': {
      color: 'gray'
    }
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    margin: '0 16px'
  },
  search: {
    width: '60%'
  },
  menu: {
    overflowX: 'none',
    overflowY: 'none'
  },
  tableRow: {
    '&:hover': {
      background: 'rgb(245, 245, 245)',
      cursor: 'pointer'
    }
  }
};

const DonorActivityTable = props => {
  const { classes, user } = props;

  const [rows, setRows] = useState([]);
  const [canDrives, setCanDrives] = useState([]);

  useEffect(() => {
    console.log("donorsCanDrives");
    const donorsCanDrives = props.canDrives.filter(canDrive => canDrive.donations.some(d => d.user && d.user.id === user.id));
    console.log(donorsCanDrives);

    // Join Ad Hoc Pickups
    let groupedPickups = [];
    let canDrives = donorsCanDrives.filter(row => row.canDriveTypeId !== 3);
    let adHocPickups = donorsCanDrives.filter(row => row.canDriveTypeId === 3);

    if (adHocPickups.length > 0) {
      adHocPickups.forEach(pickup => {
        const { date } = pickup;

        if (groupedPickups.some(item => moment(item.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY") && item.organization.name === pickup.organization.name)) {
          groupedPickups.map(groupedPickup => {
            if (moment(groupedPickup.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY")) {
              groupedPickup.totalApproximateCansCollected += pickup.totalApproximateCansCollected;
              groupedPickup.organizationTotalApproximateCansCollected += pickup.totalApproximateCansCollected;
            }
          });
        } else {
          groupedPickups.push(pickup);
        }
      });
    }

    setRows([...canDrives, ...groupedPickups]);

  }, [props])

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');
  // rowsPerPage and setRowsPerPage are used for the TablePagination component.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // table sorting functionality
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const onSort = property => event => {
    //onRequestSort(event, property);
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  return (
    <div key={user.id}>
      <Typography variant="h6" className={classes.title}>{user.fullName}</Typography>

      {/* Table */}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === "organization"}
                  direction={order}
                  onClick={onSort("organization")}
                >
                  Collector Name
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={order}
                  onClick={onSort("title")}
                >
                  Can Drive Title
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === "date"}
                  direction={order}
                  onClick={onSort("date")}
                >
                  Can Drive Date
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel>
                  Date/Time of Collection
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === "canDriveTypeId"}
                  direction={order}
                  onClick={onSort("canDriveTypeId")}
                >
                  Type
                </TableSortLabel>
              </TableCell>

              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "totalApproximateCansCollected"}
                  direction={order}
                  onClick={onSort("totalApproximateCansCollected")}
                >
                  Total Approximate Donated
                </TableSortLabel>
              </TableCell>

              <TableCell align="center"
                active={orderBy === "organizationTotalApproximateCansCollected"}
                direction={order}
                onClick={onSort("organizationTotalApproximateCansCollected")}
              >
                <TableSortLabel>
                  Total Approximate Collected
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody key={props.user.id}>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((canDrive, index) => {
                return (
                  <TableRow className={classes.tableRow} key={canDrive.id}>
                    <TableCell component="th" scope="row">
                      {canDrive.organization.name}
                    </TableCell>

                    <TableCell align="left">
                      {canDrive.canDriveTypeId === 3 ? "Ad Hoc Pickups" : canDrive.title}
                    </TableCell>

                    <TableCell align="left">
                      {moment(canDrive.date).format("MM/DD/YYYY")}
                    </TableCell>

                    <TableCell align="left">
                      {canDrive.donations.filter(d => d.userId === user.id).length > 0 ? moment.utc(canDrive.donations.filter(d => d.userId === user.id)[0].createdDateTime).local().format("MM/DD/YYYY hh:mm A") : ""}
                    </TableCell>

                    <TableCell align="left">
                      {canDrive.canDriveTypeId === 1 ? "Drop Off" :
                        canDrive.canDriveTypeId === 2 ? "Pick Up" :
                          canDrive.canDriveTypeId === 3 ? "Ad Hoc" :
                            null}
                    </TableCell>

                    <TableCell align="center">
                      {canDrive.donations.filter(d => d.userId === user.id).length > 0 ? canDrive.donations.filter(d => d.userId === user.id)[0].donorCanEstimate : ""}
                    </TableCell>

                    <TableCell align="center">
                      {canDrive.donations.filter(d => d.userId === user.id).length > 0 ? canDrive.donations.filter(d => d.userId === user.id)[0].organizationCanEstimate : ""}
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    canDrives: state.canDrives.list,
    organizations: state.organizations.list,
    user: state.users.currentUser
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(DonorActivityTable);

