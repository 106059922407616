import axios from 'axios';
import baseAction from './baseAction';
import {
  GET_8323s
} from './actionTypes';



export const get8323Reports = () => dispatch => {
  baseAction.get("8323Reports").then(res => {
    dispatch({
      type: GET_8323s,
      payload: res.data
    });
  }).catch(err => { });
};
