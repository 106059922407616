// react
import React, { useEffect } from 'react';

// redux
import { getNotifications } from '../../redux/actions/pushNotificationsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';

// material ui
import { withStyles } from '@material-ui/core';

// components
import NotificationsTable from './NotificationsTable';

// styles
const styles = {
  root: {
    position: 'relative',
    width: '100%',
    height: '800px',
    overflow: 'auto',
  },
};

function NotificationsPage(props) {
  const { classes } = props;
  useEffect(() => {
    props.getNotifications();
  }, [null]);

  return (
    <div className={classes.root}>
      <NotificationsTable />
    </div>
  );
}

export default compose(
  connect(null, { getNotifications }),
  withStyles(styles)
)(NotificationsPage);
