// react
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { refundTransaction } from '../../redux/actions/organizationActions';

// material ui
import {
  Paper, Typography,
  withStyles,
  Grid,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import moment from 'moment';

//components

//icons

// styles
const styles = {
  information: {
    textTransform: 'capitalize'
  }
}

function BillingItem(props) {
  const { classes } = props;

  const onRefund = () => {
    props.refundTransaction(props.transactionId);
  }

  return (
    <Grid container justify="space-between">
      <Grid item xs={2}>
        <Typography variant="subtitle1" className={classes.information}>{moment(props.date).format("MM/DD/YYYY")}</Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="subtitle1" className={classes.information}>${(props.amount / 100).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography variant="subtitle1" className={classes.information}>
          {props.transactionType === 1 ?
            "Annual Subscription" :
            props.transactionType === 2 ?
              "Auto Reconciliation" :
              props.transactionType === 3 ?
                "Manual Reconciliation" :
                ""}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="subtitle1" className={classes.information}>{props.status === "succeeded" ? " ACCEPTED" : props.status.toUpperCase()}</Typography>
      </Grid>

      <Grid item xs={2}>
        {props.status === "succeeded" && props.transactionType === 3 ? (
          <Button color="secondary" onClick={onRefund}>Refund</Button>
        ) : null}
      </Grid>
    </Grid>
  )
}

const mapDispatchToProps = {
  refundTransaction
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(BillingItem);
