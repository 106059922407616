// react
import React, { Fragment, useState, useEffect } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createTransactionNoApi } from '../../redux/actions/organizationActions';

// material ui
import {
  Button, Dialog, DialogActions, DialogContent,
  Typography, withStyles,
} from '@material-ui/core';

//components
import BillingItem from './BillingItem';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { showNotification } from '../../redux/actions/notificationActions';

// styles
const styles = {
  paper: {
    minHeight: '250px',
    maxHeight: '700px',
    minWidth: '700px',

    '@media only screen and (max-width: 700px)': {
      borderRadius: 'unset',
      minHeight: '100%',
      minWidth: '100%'
    }
  },

  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderBottom: '1px solid lightgray'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },

  dialogActionsContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between'
  },

  submitButton: {
    margin: '10px',
  }
}

function BillingDialog(props) {
  const { classes } = props;
  const [organization, setOrganization] = useState("Placeholder Organization");
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (props.organization !== undefined) {
      setOrganization(props.organization);
    }
  }, [props.organization]); //Only re-run the effect if props.organization changes!

  useEffect(() => {
    if (props.transactions !== undefined) {
      setTransactions(props.transactions);
    }
  }, [props.transactions]); //Only re-run the effect if props.transactions changes!

  const handleClose = () => {
    props.onClose();
  }

  //What is the difference between exit and close?
  const onExit = () => {
    console.log("Exit");
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Dialog open={props.open} classes={{ paper: classes.paper }} onExit={onExit}>
        <div className={classes.dialogTitleContainer}>
          <div className={classes.dialogTitle}>
            <Typography variant="h6"> {`${organization.name} Billing`}</Typography>
          </div>
        </div>
        <DialogContent>
          {
            props.organization.transactions ?
              props.organization.transactions.filter(transaction => transaction.transactionTypeId !== 4).map((transaction, index) =>
                // transaction.transactionTypeId 4 = Update Billing, which always has a charge amount of $0.00 and 
                // does not need to appear on this list.
                <div key={transaction.id}>
                  <BillingItem
                    date={transaction.date}
                    amount={transaction.amount}
                    organizationId={props.organization.id}
                    transactionId={transaction.id}
                    transactionType={transaction.transactionTypeId}
                    index={index} //Index is not currently being used
                    status={transaction.status}
                  />
                  <br />
                </div>
              ) : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider >
  )
}

const mapStateToProps = state => {
  return {
    organization: state.organizations.currentOrganization,
    transactions: state.organizations.transactions,
  }
}

export default compose(
  connect(mapStateToProps, { createTransactionNoApi }),
  withStyles(styles)
)(BillingDialog);
