import { LOGIN_USER, LOGGED_IN_USER, LOGIN_FAIL, LOGOUT_USER } from './actionTypes';
import axios from 'axios';
import baseAction from './baseAction';
import { showNotification } from './notificationActions';
import jwt from 'jsonwebtoken';

export const checkAuth = () => dispatch => {
  if (localStorage.getItem("auth_token")) {
    const currentToken = localStorage.getItem("auth_token");
    const decodedToken = jwt.decode(currentToken);

    dispatch({
      type: LOGGED_IN_USER,
      payload: decodedToken
    })
  } else {
    localStorage.clear();
  }
}

export const loginUser = (username, password) => (dispatch, getState) => {
  dispatch({
    type: LOGIN_USER,
    payload: null
  });

  axios.post(process.env.REACT_APP_API_URL + "/account/login", { username, password }).then(res => {
    dispatch({
      type: LOGGED_IN_USER,
      payload: res.data
    });

    const { auth_token } = getState().auth;
    localStorage.setItem("auth_token", auth_token);

    // TODO URGENT: this needs to be moved to the back end
    showNotification(Boolean(res.data.roles.find(role => role === "admin")) ?
      "Logged in successfully!" :
      "Login failed.");
  }).catch(() => {
    dispatch({
      type: LOGIN_FAIL,
      payload: null
    });
    showNotification("Invalid username or password.")
  })
}

export const logoutUser = () => dispatch => {
  localStorage.clear();

  dispatch({
    type: LOGOUT_USER,
    payload: null
  });
  showNotification("Logged out successfully.");
};

export const confirmEmail = (e, t) => {
  const email = encodeURI(e).replace(/%20/g, "+");
  const token = encodeURI(t)//.replace(/%20/g, "+");

  axios.post(process.env.REACT_APP_API_URL + "/account/confirm_email", { email, token }).then(() => {
    showNotification("Thank you for verifying your email address.")
  }).catch(err => { });
}

export const forgotPassword = email => {
  axios.post(process.env.REACT_APP_API_URL + "/account/forgot_password", { email })
    .catch(err => { });

  showNotification(`A password reset email has been sent to ${email}`);
};

export const resetPassword = (e, t, password) => {
  const email = encodeURI(e).replace(/%20/g, "+");
  const token = encodeURI(t).replace(/%20/g, "+");

  axios.post(process.env.REACT_APP_API_URL + "/account/reset_password", { email: email, token, password }).then(() => {
    showNotification("Password reset successfully!");
  }).catch(err => { });
};

export const changePassword = (currentPassword, newPassword) => {

  baseAction.post("/account/change_password", { currentPassword, newPassword })
    .then(() => showNotification("Password updated successfully."))
    .catch(err => showNotification(err.response.data.message));
};

export const register = (firstName, lastName, email, phoneNumber, address, password) => {
  const data = {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    password
  }

  axios.post(process.env.REACT_APP_API_URL + "/account/register", data)
    .catch(err => { });
}

// export const joinTeam = (organizationId, email) => {
//   axios.post(process.env.REACT_APP_API_URL + "/account/join_team/" + organizationId, { email })
//     .catch(err => { });
// }

export const registerTeamMember = (firstName, lastName, email, phoneNumber, password, token) => {
  const t = encodeURI(token).replace(/%20/g, "+");
  const data = {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    token: t
  }

  axios.post(process.env.REACT_APP_API_URL + "/account/register_team_member", data)
    .then(showNotification("Thank you for verifying your email address."))
    .catch(err => { });
}
