// react
import React, { useEffect } from 'react';

// redux
import { getUsers } from '../../redux/actions/userActions';
import { compose } from 'redux';
import { connect } from 'react-redux';

// material ui
import { withStyles } from '@material-ui/core';

// components
import UsersTable from './UsersTable';

// styles
const styles = {
  root: {
    position: 'relative',
    width: '100%',
    height: '800px',
    overflow: 'auto'
  }
};

function UsersPage(props) {
  const { classes } = props;
  useEffect(() => {
    props.getUsers();
  }, [null]);

  return (
    <div className={classes.root}>
      <UsersTable />
    </div>
  )
};

export default compose(
  connect(null, { getUsers }),
  withStyles(styles)
)(UsersPage);
