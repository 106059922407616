// react
import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';

// material ui
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function SnackbarNotification(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.notification.message != null)
      setOpen(true);
  }, [props.notification]);

  function handleClose() {
    setOpen(false);
  }

  return props.notification ? (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{props.notification.message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  ) : null;
}

const mapStateToProps = state => {
  return {
    notification: state.notification
  }
}

export default connect(mapStateToProps)(SnackbarNotification);
