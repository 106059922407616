import { SHOW_NOTIFICATION } from './actionTypes';
import { store } from '../store';
import uuid from 'uuid';

export const showNotification = message => {
  // Because we are dispatching directly on the store consumers
  // of this function won't have to mapDispatchToProps.
  store.dispatch({
    type: SHOW_NOTIFICATION,
    payload: {
      message,
      ud: uuid()
    }
  })
};
