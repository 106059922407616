import React, { useEffect, useState } from "react";

import { confirmEmail } from "../../redux/actions/authActions";

import { Button, Grid, Typography, withStyles, Link } from "@material-ui/core";

import queryString from "query-string";

const styles = {
  container: {
    marginTop: "32px",
  },
  title: {
    margin: "10px 0 10px 0",
    color: "#fff",
    fontWeight: "bold",
  },
  bodyText: {
    color: "#fff",
  },
};

function ConfirmEmailPage(props) {
  const { classes } = props;
  const [params] = useState(queryString.parse(props.location.search));

  useEffect(() => {
    // params.e is the email address
    // params.t is the token
    if (params.t) {
      confirmEmail(params.e, params.t);
    }
  }, [null]);

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      style={{
        backgroundColor: "#19bfe7",
        padding: 0,
        margin: 0,
        minHeight: "100vh",
      }}
    >
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="CanDrive"
        width="200"
      />
      <Grid item className={classes.container}>
        <Typography variant="h5" align="center" className={classes.title}>
          Verification complete!
        </Typography>
        <Typography variant="body1" align="center" className={classes.bodyText}>
          Thank you for verifying your email address.
        </Typography>
        <Typography variant="body1" align="center" className={classes.bodyText}>
          Please use the links below to download the CanDrives app for your
          device.
        </Typography>
      </Grid>
      <Grid item className={classes.container}>
        <Typography variant="h5" align="center" className={classes.title}>
          New to Can Drives?
        </Typography>
        <Typography variant="body1" align="center" className={classes.bodyText}>
          Click{" "}
          <span>
            <a
              href="https://www.candrives.com/tutorials/?utm_source=tutorials&utm_medium=new%20user%20email&utm_campaign=email"
              className={classes.bodyText}
            >
              here
            </a>
          </span>{" "}
          for helpful tutorials!
        </Typography>
      </Grid>
      <Grid item className={classes.container}>
        <Typography variant="h5" align="center" className={classes.title}>
          Already have the app installed?
        </Typography>
        <Typography variant="body1" align="center" className={classes.bodyText}>
          Please open the app now and login with your email and password.
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Typography variant="h5" align="center" className={classes.title}>
            Don't have the app yet?
          </Typography>
          <Typography
            variant="body1"
            align="center"
            className={classes.bodyText}
          >
            Click below to download the app from App Store or Google Play.
          </Typography>
        </Grid>
        <Grid item container alignItems="center" justify="center">
          <Link href="https://apps.apple.com/us/app/candrives/id1515595403">
            <img
              src={process.env.PUBLIC_URL + "/App_Store.png"}
              alt="App Store"
              width="160"
            />
          </Link>
          <Link href="https://play.google.com/store/apps/details?id=com.candrivemobileapp">
            <img
              src={process.env.PUBLIC_URL + "/google-play-badge.png"}
              alt="Google Play Store"
              width="200"
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ConfirmEmailPage);
