import { CREATE_USER, GET_USERS, GET_USER_BY_ID, UPDATE_USER, LOGOUT_USER } from '../actions/actionTypes';

const initialState = {
  list: [],
  currentUser: {}
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_USER:
      return {
        list: [...state.list, payload],
        currentUser: payload
      }

    case GET_USERS:
      return {
        ...state,
        list: payload
      };

    case GET_USER_BY_ID:
      return {
        ...state,
        currentUser: payload
      };

    case UPDATE_USER:
      return {
        list: state.list.map(user => user.id === payload.id ? payload : user),
        currentUser: payload
      }

    case LOGOUT_USER:
      return initialState;

    default:
      return state;
  }
}

