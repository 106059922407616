// react
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// material ui
import { Badge, Button, Typography, withStyles } from '@material-ui/core';

// styles
const styles = {
  button: {
    color: 'inherit',
    height: '75px',
    borderRadius: '0',
    textTransform: 'none',
    display: 'inline-block',
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  navLink: {
    color: 'inherit',
    textDecoration: 'none',

    '&:hover': {
      opacity: 8
    }
  }
}

// this component is responsible for displaying/creating navigation items. the parent component
// must supply the number of past due items (if applicable) for the badge.
function NavigationItem(props) {
  const { classes } = props;
  return (
    <div>
      <NavLink to={props.to} className={classes.navLink}>
        <Button className={classes.button}>
          <Badge badgeContent={props.badgeCount} max={9} color="secondary">
            <Typography>
              {props.title}
            </Typography>
          </Badge>
        </Button>
      </NavLink>
    </div>
  )
}

NavigationItem.propTypes = {
  title: PropTypes.string.isRequired,
  badgeCount: PropTypes.number,
  to: PropTypes.string.isRequired
}

export default withStyles(styles)(NavigationItem);
